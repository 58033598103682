import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_auth_info = _resolveComponent("auth-info")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_logo, { isLight: "" }),
    _createVNode(_component_auth_info)
  ]))
}