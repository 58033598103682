import { Module } from 'vuex';
import RootState from '@/store/models';

const VISIBLE = 'VISIBLE';
const DROP = 'DROP';
const DISABLE = 'DISABLE';

interface SidebarState {
  visible: boolean | null;
  disableClose: boolean;
}

const moduleSidebar: Module<SidebarState, RootState> = {
  namespaced: true,
  state: (): SidebarState => ({
    visible: null,
    disableClose: false,
  }),
  mutations: {
    [VISIBLE]: (state: SidebarState, status) => {
      state.visible = status;
    },
    [DROP]: (state: SidebarState) => {
      state.visible = null;
    },
    [DISABLE]: (state: SidebarState, payload: boolean) => {
      state.disableClose = payload;
    },
  },
  actions: {
    disable({ commit }, payload: boolean) {
      commit(DISABLE, payload);
    },
    toggle({ commit, state }) {
      return state.visible ? commit(DROP) : commit(VISIBLE, true);
    },
    open({ commit, state }) {
      if (state.visible) return;
      commit(VISIBLE, true);
    },
    close({ commit, state }) {
      if (state.disableClose) return;
      commit(DROP);
    },
  },
};

export default moduleSidebar;
