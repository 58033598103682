import {
  createRouter, createWebHistory, RouteLocation, RouteRecordRaw,
} from 'vue-router';
import NotFound from '@/views/NotFound.vue';
import analyticsRoutes from '@/router/analytics';
import sideNavigationRoutes from '@/router/side-navigation';
import userRoutes from '@/router/user';
import productRoutes from '@/router/products';
import promotionRoutes from './promotions';
import instructionRoutes from './instructions';
import reports from './reports';
import reviews from './reviews';
import constructor from './constructor';

const routes: Array<RouteRecordRaw> = [
  ...sideNavigationRoutes,
  ...userRoutes,
  ...promotionRoutes,
  ...productRoutes,
  ...analyticsRoutes,
  ...instructionRoutes,
  ...reports,
  ...reviews,
  ...constructor,
  {
    meta: { title: 'Что нового?' },
    name: 'WhatsNew',
    path: '/what-s-new',
    alias: '/what-s-new/',
    component: () => import(/* webpackChunkName: "what-s-new" */ '@/views/what-s-new/WhatsNew.vue'),
  },
  {
    name: 'UserAgreement',
    path: '/user-agreement',
    alias: '/user-agreement/',
    component: () => import(/* webpackChunkName: "what-s-new" */ '@/views/user-agreement/UserAgreement.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  scrollBehavior: (to: RouteLocation, from: RouteLocation) => scrollBehavior(to, from),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const scrollBehavior = function routerScrollBehavior(to: RouteLocation, from: RouteLocation): any {
  const position = { x: 0, y: 0 };

  if (from.name === to.name) return false;
  return position;
};

export default router;
