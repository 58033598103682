import { Module } from 'vuex';
import RootState from '@/store/models';
import { FavoritesState } from '@/store/favorites/models';
import actions from '@/store/favorites/actions';
import getters from '@/store/favorites/getters';
import mutations from '@/store/favorites/mutations';

const moduleFavorites: Module<FavoritesState, RootState> = {
  namespaced: true,
  state: () => ({
    favorites: null,
    loadingFavorites: false,
    loadingDeleting: null,
    favoritesCount: 0,
    showDeletePopup: null,
    selectedFavorite: null,
  }),
  actions,
  mutations,
  getters,
};

export default moduleFavorites;
