import { Ref, ref } from 'vue';
import { ResizeObserver, ResizeObserverCallback } from '@/models/resizeObserver';

interface useIsMobile {
  isMobile: Ref<boolean>;
  observeResize: (enable: boolean) => void;
}

export function useIsMobile(): useIsMobile {
  const isMobile = ref<boolean>(false);
  const resizeObserver = ref<ResizeObserver | null>(null);

  const observeResize = (enable = true) => {
    if (!document.body || typeof window === 'undefined') return onresize();
    if (!(window as any).ResizeObserver) return onresize();

    if (!enable) {
      if (resizeObserver.value) resizeObserver.value.disconnect();
      resizeObserver.value = null;
      return resizeObserver.value;
    }

    if (!resizeObserver.value) {
      const callback: ResizeObserverCallback = () => {
        onresize();
      };
      resizeObserver.value = new (window as any).ResizeObserver(callback);
    }

    (resizeObserver.value as any).observe(document.body as HTMLElement);
    return undefined;
  };

  const onresize = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileUA = /mobile/.test(userAgent);
    // eslint-disable-next-line max-len
    const tabletUA = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    const uaWidth = mobileUA && !tabletUA ? 744 : 1201;
    const width = typeof window !== 'undefined' ? window.innerWidth : uaWidth;
    const isTouchDevice = ('ontouchstart' in document.documentElement);

    isMobile.value = (mobileUA && !tabletUA) || width <= 744;
    if (!isMobile.value && typeof window !== 'undefined' && isTouchDevice) {
      if (window.innerWidth < window.innerHeight) isMobile.value = true;
    }
  };

  return {
    isMobile,
    observeResize,
  };
}
