<template>
<span class="spinner">
  <svg viewBox="0 0 50 50">
    <circle cx="25" cy="25" r="20" fill="none" stroke-width="5"/>
  </svg>
</span>
</template>

<style lang="less">
@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.spinner {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  padding: 1px;
  height: 52px;
  width: 52px;
  color: @grey;
  stroke-width: 5;

  &[hidden] {
    display: none
  }
}

.spinner > svg {
  display: block;
  width: 100%;
  height: 100%;
  animation: spinner-spin 2s linear infinite;

  & > circle {
    animation: spinner-dash 1.5s ease-in-out infinite;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-width: inherit;
  }
}
</style>
