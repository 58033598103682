import { MutationTree } from 'vuex';
import { FavoritesState } from '@/store/favorites/models';
import {
  FAVORITES,
  LOADING_FAVORITES,
  DROP_FAVORITES,
  FAVORITES_COUNT,
  LOADING_DELETING,
  SHOW_DELETE_POPUP,
  SELECTED_FAVORITE,
} from '@/store/favorites/mt';
import ResponsePage = Models.Common.ResponsePage;
import FavoriteRequestInfo = Models.Favorites.FavoriteRequestInfo;

const mutations: MutationTree<FavoritesState> = {
  [FAVORITES]: (state: FavoritesState, payload: ResponsePage<FavoriteRequestInfo>) => {
    state.favorites = payload;
  },
  [LOADING_FAVORITES]: (state: FavoritesState, payload: boolean) => {
    state.loadingFavorites = payload;
  },
  [DROP_FAVORITES]: (state: FavoritesState) => {
    state.favorites = null;
  },
  [FAVORITES_COUNT]: (state: FavoritesState, payload: number) => {
    state.favoritesCount = payload;
  },
  [LOADING_DELETING]: (state: FavoritesState, payload: string | null) => {
    state.loadingDeleting = payload;
  },
  [SHOW_DELETE_POPUP]: (
    state: FavoritesState,
    payload: { name: string, type: string, favoriteUid: string } | null,
  ) => {
    state.showDeletePopup = payload;
  },
  [SELECTED_FAVORITE]: (
    state: FavoritesState,
    payload: { name: string, type: string, favoriteUid: string } | null,
  ) => {
    state.selectedFavorite = payload;
  },
};

export default mutations;
