import { RouteRecordRaw } from 'vue-router';

const reports: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Отчеты' },
    path: '/reports',
    alias: '/reports/',
    name: 'Reports',
    component: () => import(
      /* webpackChunkName: "Reports" */
      '@/views/reports/Reports.vue'
    ),
  },
  {
    meta: { title: 'Power BI Report' },
    path: '/reports/:id',
    alias: '/reports/:id/',
    name: 'ReportPowerBi',
    component: () => import(
      /* webpackChunkName: "Report" */
      '@/views/reports/ReportPowerBi.vue'
    ),
  },
  {
    meta: { title: 'Report Olap' },
    path: '/reports/olap/:id',
    alias: '/reports/olap/:id/',
    name: 'ReportOlap',
    component: () => import(
      /* webpackChunkName: "Report" */
      '@/views/reports/ReportOlap.vue'
    ),
  },
  {
    meta: { title: 'Report Sql' },
    path: '/reports/sql/:id',
    alias: '/reports/sql/:id/',
    name: 'ReportSql',
    component: () => import(
      /* webpackChunkName: "Report" */
      '@/views/reports/ReportOlap.vue'
    ),
  },
];

export default reports;
