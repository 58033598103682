
import { defineComponent } from 'vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import ButtonComponent from '@/components/buttons/Button.vue';
import ResetPassword from '@/views/reset-password/ResetPassword.vue';

export default defineComponent({
  name: 'ResetPasswordView',
  components: {
    ResetPassword,
    ButtonComponent,
    AuthLayout,
  },
  data() {
    return {
      newPassword: '',
      strong: false,
      confirmPassword: '',
      resetCode: '',
      email: '',
      success: false,
    };
  },
  created() {
    this.$store.dispatch('user/logout');
    const { code, email } = this.$route.query;
    if (code && typeof code === 'string' && email && typeof email === 'string') {
      this.resetCode = code;
      this.email = email;
    } else {
      this.goHome();
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' });
    },
    strength(strong: boolean) {
      this.strong = strong || false;
    },
  },
  computed: {
    disabled(): boolean {
      return !this.strong || this.newPassword !== this.confirmPassword;
    },
  },
});
