
import { defineComponent } from 'vue';
import Logo from '@/components/layout/Logo.vue';
import Crumbs from '@/components/crumbs/Crumbs.vue';
import { Crumb } from '@/views/products/types';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'UserAgreement',

  components: {
    Logo,
    Crumbs,
  },

  computed: {
    ...mapState('user', ['token']),
    crumbs(): Crumb[] {
      return [
        {
          label: !this.token ? 'Авторизация' : 'Главная',
          route: { name: 'Home' },
        },
        {
          label: 'Пользовательское соглашение',
        },
      ];
    },
  },
});
