import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import cookies from '@/utils/cookie';

interface BackendURLs {
  backendURL: string;
  aruBackendURL: string;
}

const backends = cookies.get('backends');
const urls: BackendURLs | undefined = backends ? JSON.parse(backends) : undefined;

const ARU_BACKEND_URL = urls?.aruBackendURL || undefined;

function createApiClient(): AxiosInstance {
  return axios.create({
    baseURL: urls?.backendURL,
    responseType: 'json',
    paramsSerializer: (params: any): string => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
}

export {
  createApiClient,
  ARU_BACKEND_URL,
};
