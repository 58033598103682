
import { defineComponent } from 'vue';
import NewPassword from '@/components/inputs/InputNewPassword.vue';
import InputPassword from '@/components/inputs/InputPassword.vue';
import ButtonComponent from '@/components/buttons/Button.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import { restorePassword } from '@/store/user/api';
import { AxiosError } from 'axios';
import { reasonMessage } from '@/utils/reason-message';
import { mapActions } from 'vuex';
import cookies from '@/utils/cookie';

interface ComponentData {
  newPassword: string;
  strong: boolean;
  confirmPassword: string;
  success: boolean;
  userAgreement: boolean;
}

export default defineComponent({
  name: 'ResetPassword',
  components: {
    ButtonComponent,
    InputPassword,
    NewPassword,
    InputCheckbox,
  },
  data(): ComponentData {
    return {
      newPassword: '',
      strong: false,
      confirmPassword: '',
      success: false,
      userAgreement: true,
    };
  },
  methods: {
    ...mapActions('message', {
      addMessage: 'add',
    }),
    async setNewPassword(): Promise<void> {
      return restorePassword({
        resetCode: this.resetCode,
        newPassword: this.newPassword,
      }).then(() => {
        this.$emit('success');
        cookies.set('userAgreement', '1', { expires: 365 * 10 * 24 * 60 });
      }).catch(async (reason: AxiosError) => this.addMessage(reasonMessage(reason)));
    },
    strength(strong: boolean): void {
      this.strong = strong || false;
    },
    changeUserAgreement(val: boolean): void {
      if (val) cookies.set('userAgreement', '1', { expires: 365 * 10 * 24 * 60 });
      else cookies.del('userAgreement');
    },
  },
  props: {
    email: String,
    resetCode: String,
  },
  computed: {
    isNew(): boolean {
      const { isNew } = this.$route.query;
      return typeof isNew === 'string' && isNew.toLowerCase() === 'true';
    },
    passwordMismatch(): boolean {
      return this.newPassword !== this.confirmPassword;
    },
    disabled(): boolean {
      const invalidPasswords = !this.strong || this.passwordMismatch || !this.userAgreement;
      if (this.isNew) return invalidPasswords;
      return invalidPasswords;
    },
  },
});
