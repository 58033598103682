import { RouteRecordRaw } from 'vue-router';

const promotionRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Скидки' },
    path: '/promotions/discount',
    alias: '/promotions/discount/',
    name: 'Discount',
    component: () => import(
      /* webpackChunkName: "discount" */
      '@/views/promotions/discount/DiscountView.vue'
    ),
    children: [
      {
        meta: { title: 'Создать' },
        path: 'create',
        alias: 'create/',
        name: 'CreateDiscountTemplate',
        component: () => import(
          /* webpackChunkName: "discount" */
          '@/views/promotions/discount/create-or-edit-template/CreateDiscountTemplate.vue'
        ),
      },
      {
        meta: { title: 'Скидка' },
        path: ':uid?',
        alias: ':uid?/',
        name: 'ViewCreatedDiscount',
        component: () => import(
          /* webpackChunkName: "discount" */
          '@/views/promotions/discount/view-created-discount/ViewCreatedDiscount.vue'
        ),
      },
      {
        meta: { title: 'Заявка на скидку' },
        path: 'edit/:uid?',
        alias: 'edit/:uid?',
        name: 'EditDiscountTemplate',
        component: () => import(
          /* webpackChunkName: "discount" */
          '@/views/promotions/discount/create-or-edit-template/EditDiscountTemplate.vue'
        ),
      },
    ],
  },
  {
    meta: { title: 'Календарь акций' },
    path: '/promotions/calendar',
    alias: '/promotions/calendar/',
    name: 'PromotionsCalendar',
    component: () => import(
      /* webpackChunkName: "PromotionsCalendar" */
      '@/views/promotions/calendar/PromotionsCalendar.vue'
    ),
  },
  {
    meta: { title: 'Комплекты' },
    path: '/promotions/good-set',
    alias: '/promotions/good-set/',
    name: 'GoodSets',
    component: () => import(
      /* webpackChunkName: "good-set" */
      '@/views/promotions/good-set/GoodSetView.vue'
    ),
    children: [
      {
        meta: { title: 'Создать' },
        path: 'create',
        alias: 'create/',
        name: 'CreateGoodSetTemplate',
        component: () => import(
          /* webpackChunkName: "good-set" */
          '@/views/promotions/good-set/create-or-edit-template/TemplateView.vue'
        ),
      },
      {
        meta: { title: 'Заявка на комплект' },
        path: 'edit/:uid?',
        alias: 'edit/:uid?',
        name: 'EditGoodSetTemplate',
        component: () => import(
          /* webpackChunkName: "good-set" */
          '@/views/promotions/good-set/create-or-edit-template/TemplateView.vue'
        ),
      },
      {
        meta: { title: 'Комплект' },
        path: ':uid',
        alias: ':uid/',
        name: 'ViewGoodSet',
        component: () => import(
          /* webpackChunkName: "good-set" */
          '@/views/promotions/good-set/view-good-set/ViewGoodSet.vue'
        ),
      },
    ],
  },
  {
    meta: { title: 'Прогрессивные скидки' },
    path: '/promotions/pr-discount',
    alias: '/promotions/pr-discount/',
    name: 'PrDiscounts',
    component: () => import(
      /* webpackChunkName: "pr-discount" */
      '@/views/promotions/pr-discount/PrDiscountView.vue'
    ),
  },
  {
    meta: { title: 'Создать' },
    path: '/promotions/pr-discount/create',
    alias: '/promotions/pr-discount/create/',
    name: 'CreatePrDiscountTemplate',
    component: () => import(
      /* webpackChunkName: "pr-discount" */
      '@/views/promotions/pr-discount/create-or-edit-template/CreatePrDiscountTemplate.vue'
    ),
  },
  {
    meta: { title: 'Прогрессивная скидка' },
    path: '/promotions/pr-discount/:uid?',
    alias: '/promotions/pr-discount/:uid?/',
    name: 'CreatedPrDiscount',
    component: () => import(
      /* webpackChunkName: "pr-discount" */
      '@/views/promotions/pr-discount/CreatedPrDiscountView.vue'
    ),
  },
  {
    meta: { title: 'Заявка на скидку' },
    path: '/promotions/pr-discount/edit/:uid?',
    alias: '/promotions/pr-discount/edit/:uid?/',
    name: 'EditPrDiscountTemplate',
    component: () => import(
      /* webpackChunkName: "pr-discount" */
      '@/views/promotions/pr-discount/create-or-edit-template/EditPrDiscountTemplate.vue'
    ),
  },
  {
    meta: { title: 'Промокоды' },
    path: '/promotions/promocodes',
    alias: '/promotions/promocodes/',
    name: 'Promocodes',
    component: () => import(
      /* webpackChunkName: "promocodes" */
      '@/views/promotions/promocodes/PromocodesView.vue'
    ),
  },
  {
    path: '/promotions/promocodes/:uid',
    alias: '/promotions/promocodes/:uid/',
    name: 'Promocode',
    component: () => import(
      /* webpackChunkName: "promocode" */
      '@/views/promotions/promocodes/PromocodeCreateEditView.vue'
    ),
  },
  {
    path: '/promotions/promocodes/created/:uid',
    alias: '/promotions/promocodes/created/:uid/',
    name: 'PromocodeCreated',
    component: () => import(
      /* webpackChunkName: "promocode" */
      '@/views/promotions/promocodes/PromocodeNotEditable.vue'
    ),
  },
];

export default promotionRoutes;
