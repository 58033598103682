import { Module } from 'vuex';
import RootState from '@/store/models';
import { RatingState } from '@/store/analytics/ratings/models';
import actions from '@/store/analytics/ratings/actions';
import mutations from '@/store/analytics/ratings/mutations';
import getters from '@/store/analytics/ratings/getters';

const moduleRatings: Module<RatingState, RootState> = {
  namespaced: true,
  state: () => ({
    table: null,
  }),
  actions,
  mutations,
  getters,
};

export default moduleRatings;
