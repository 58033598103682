import { GetterTree } from 'vuex';
import RootState from '@/store/models';
import { FavoritesState } from '@/store/favorites/models';
import ResponsePage = Models.Common.ResponsePage;
import FavoriteRequestInfo = Models.Favorites.FavoriteRequestInfo;

const getters: GetterTree<FavoritesState, RootState> = {
  favorites: (state: FavoritesState): ResponsePage<FavoriteRequestInfo> | null => state.favorites,
  loadingFavorites: (state: FavoritesState): boolean => state.loadingFavorites,
  loadingDeleting: (state: FavoritesState): string | null => state.loadingDeleting,
  favoritesCount: (state: FavoritesState): number => state.favoritesCount,
  showDeletePopup: (state: FavoritesState):
    { name: string, type: string, favoriteUid: string } | null => state.showDeletePopup,
  selectedFavorite: (state: FavoritesState):
    { name: string, type: string, favoriteUid: string } | null => state.selectedFavorite,
};

export default getters;
