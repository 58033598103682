
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import IconSvg from '@/components/icon-svg.vue';
import { useWhatsNew } from '@/composables/useWhatsNew';

export default defineComponent({
  name: 'AuthInfo',
  setup() {
    const { newsCount, getNewsCount } = useWhatsNew();

    return {
      newsCount,
      getNewsCount,
    };
  },
  mounted() {
    this.getNewsCount();
  },
  components: { IconSvg },
  computed: mapState('user', ['info']),
});
