import { RouteRecordRaw } from 'vue-router';

const analyticsRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Продажи' },
    path: '/analytics/sales',
    alias: '/analytics/sales/',
    name: 'Sales',
    component: () => import(
      /* webpackChunkName: "numbers" */
      '@/views/analytics/sales/Sales.vue'
    ),
  },
  {
    meta: { title: 'Поисковые запросы' },
    path: '/analytics/search-queries',
    alias: '/analytics/search-queries/',
    name: 'SearchQueries',
    component: () => import(
      /* webpackChunkName: "numbers" */
      '@/views/analytics/search-queries/SearchQueries.vue'
    ),
  },
  {
    meta: { title: 'Рейтинг в категории' },
    path: '/analytics/ratings',
    alias: '/analytics/ratings/',
    name: 'RatingFilters',
    component: () => import(
      /* webpackChunkName: "numbers" */
      '@/views/analytics/ratings/RatingView.vue'
    ),
  },
  {
    meta: { title: 'Просмотры карточек товаров' },
    path: '/products-report',
    alias: '/products-report/',
    name: 'ProductsReport',
    component: () => import(
      /* webpackChunkName: "products-report" */
      '../views/products-reports/ProductsReportView.vue'
    ),
  },
];

export default analyticsRoutes;
