import { RouteRecordRaw } from 'vue-router';

const instructionRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Инструкции' },
    path: '/Instructions',
    alias: '/Instructions/',
    name: 'Instructions',
    component: () => import(
      /* webpackChunkName: "products" */
      '@/views/instructions/Instructions.vue'
    ),
  },
];

export default instructionRoutes;
