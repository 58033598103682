import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!

  return (_openBlock(), _createElementBlock("a", {
    class: "link-button",
    href: $props.href
  }, [
    ($props.symbol)
      ? (_openBlock(), _createBlock(_component_icon_svg, {
          key: 0,
          symbol: $props.symbol
        }, null, 8, ["symbol"]))
      : _createCommentVNode("", true),
    ($props.text)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString($props.text), 1)
        ], 64))
      : _renderSlot(_ctx.$slots, "default", { key: 2 })
  ], 8, _hoisted_1))
}