
import AuthForm from '@/components/auth/AuthForm.vue';
import RecoveryPassword from '@/components/auth/RecoveryPassword.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';

interface ComponentData {
  recoveryForm: boolean;
}

export default {
  name: 'AuthView',
  components: {
    AuthLayout,
    RecoveryPassword,
    AuthForm,
  },
  data(): ComponentData {
    return {
      recoveryForm: false,
    };
  },
};
