
import IconSvg from '@/components/icon-svg.vue';

export default {
  components: {
    IconSvg,
  },
  props: {
    isLight: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
