import { RouteRecordRaw } from 'vue-router';

const userRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Профиль' },
    path: '/profile',
    alias: '/profile/',
    name: 'Profile',
    component: () => import(
      /* webpackChunkName: "user" */
      '@/views/user/profile/ProfileView.vue'
    ),
  },
  {
    meta: { title: 'Восстановление пароля' },
    path: '/reset-password',
    alias: '/reset-password/',
    name: 'ResetPassword',
    component: () => import(
      /* webpackChunkName: "auth" */
      '@/views/reset-password/ResetPasswordView.vue'
    ),
  },
];

export default userRoutes;
