import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input-field__label" }
const _hoisted_2 = ["value", "placeholder", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "input-field__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-field input-email", {'input-field__error': $props.error}])
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createTextVNode(_toDisplayString($props.label || 'Введите Email') + " ", 1),
      _createElementVNode("input", {
        class: "input-field__input",
        id: "email",
        type: "email",
        name: "email",
        value: $props.modelValue,
        placeholder: $props.placeholder,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        disabled: $props.disabled
      }, null, 40, _hoisted_2)
    ]),
    (($props.error || $props.showHint) && $props.hint)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.hint), 1))
      : _createCommentVNode("", true)
  ], 2))
}