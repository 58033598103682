
import { defineComponent } from 'vue';
import InputEmail from '@/components/inputs/InputEmail.vue';
import InputPassword from '@/components/inputs/InputPassword.vue';
import ButtonComponent from '@/components/buttons/Button.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import { mapActions } from 'vuex';
import { AxiosError } from 'axios';
import BlockedUser from '@/components/auth/BlockedUser.vue';
import { reasonMessage } from '@/utils/reason-message';
import cookies from '@/utils/cookie';

interface ComponentData {
  email: string;
  password: string;
  loading: boolean;
  userAgreement: boolean;
  error: boolean;
  manager: {
    name: string;
    email: string;
  } | null,
}

export default defineComponent({
  name: 'AuthForm',

  components: {
    BlockedUser,
    ButtonComponent,
    InputEmail,
    InputPassword,
    InputCheckbox,
  },

  data(): ComponentData {
    return {
      email: '',
      password: '',
      userAgreement: true,
      loading: false,
      error: false,
      manager: null,
    };
  },

  watch: {
    email() {
      this.dropError();
    },
    password() {
      this.dropError();
    },
  },

  computed: {
    disabled(): boolean {
      return !this.userAgreement;
    },
  },

  methods: {
    ...mapActions('user', ['load']),
    ...mapActions('message', {
      addMessage: 'add',
    }),

    async login(): Promise<void> {
      if (this.disabled) return;
      this.loading = true;
      this.dropError();
      this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password,
      })
        .then(() => {
          cookies.set('userAgreement', '1', { expires: 365 * 10 * 24 * 60 });
        })
        .catch(async (reason: AxiosError) => {
          await this.addMessage(reasonMessage(reason));

          if (reason.response?.status === 403) {
            const { managerEmail, managerName } = reason.response.data;
            this.manager = { email: managerEmail, name: managerName };
            await this.$router.push({ hash: '#blocked' });
            this.email = '';
            this.password = '';
          }
          this.error = true;
          await this.$store.dispatch('user/logout');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onEnter() {
      this.login();
    },

    dropError() {
      if (this.error) this.error = false;
    },

    changeUserAgreement(val: boolean): void {
      if (val) cookies.set('userAgreement', '1', { expires: 365 * 10 * 24 * 60 });
      else cookies.del('userAgreement');
    },
  },
});
