import IPharmSalesReportNode = Models.KcsQuery.IPharmSalesReportNode;

function getMax(data?: IPharmSalesReportNode[]): number {
  let max = 0;
  data?.forEach((item: IPharmSalesReportNode) => {
    max = Math.max(max, item.amount || 0);
  });
  const l = max.toString().length;
  const rounder = 10 ** (l === 1 ? l : l - 1);
  return Math.ceil(max / rounder) * rounder;
}

export default getMax;
