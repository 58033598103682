
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

interface ComponentData {
  tooltip: boolean;
  hidePassword: boolean;
}

export default defineComponent({
  name: 'NewPassword',
  components: {
    IconSvg,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      tooltip: false,
      hidePassword: true,
    };
  },
  computed: {
    classModifier(): string | undefined {
      const modifiers = ['low', 'middle', 'high'];
      if (!this.strength || this.strength > 3) return undefined;
      return `new-password__progress--${modifiers[this.strength - 1]}`;
    },
    hint(): string | undefined {
      if (!this.strength) return undefined;
      const hints = [
        'Указанный пароль слишком прост и может быть похищен злоумышленниками.'
        + ' Пожалуйста, придумайте более сложный пароль',
        'Хорошее начало!',
        'Надёжный пароль.',
      ];
      return hints[this.strength - 1];
    },
    strength(): number {
      const value = this.modelValue || '';
      const len: number = value.trim().length;

      const symbols = value.match(/[#?!@$%^&*№-]/);
      const numbers = value.match(/\d+/);
      const diffCases = value.match('.*[a-zа-я].*') && value.match('.*[A-ZА-Я].*');

      let strength = 0;

      if (len >= 8 && symbols && numbers && diffCases) strength = 3;
      else if (len > 4) strength = 2;
      else if (len > 0 && len <= 4) strength = 1;

      this.$emit('strength', len >= 8);
      return strength;
    },
  },
  methods: {
    toggleType(): void {
      this.hidePassword = !this.hidePassword;
    },
    onFocus(): void {
      this.tooltip = true;
      this.$emit('focus');
    },
    onBlur(): void {
      this.tooltip = false;
    },
  },
});
