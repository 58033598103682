import { RouteRecordRaw } from 'vue-router';

const constructor: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Конструктор страниц' },
    path: '/brand-constructor',
    alias: '/brand-constructor/',
    name: 'ConstructorList',
    component: () => import(
      /* webpackChunkName: "brand-constructor" */
      '@/views/brand-constructor/ConstructorList.vue'
    ),
  },
  {
    path: '/brand-constructor/:id',
    alias: '/brand-constructor/:id/',
    name: 'Constructor',
    component: () => import(
      /* webpackChunkName: "brand-constructor" */
      '@/views/brand-constructor/ConstructorView.vue'
    ),
  },
];

export default constructor;
