import { Module } from 'vuex';
import RootState from '@/store/models';
import { DiscountType } from '@/components/promotions/discount/select-products/models';
import PrDiscountStage = Models.PrDiscount.PrDiscountStage;

const COPY_DISCOUNT = 'COPY_DISCOUNT';
const UID = 'UID';
const NAME = 'NAME';
const BEGIN_DATE = 'BEGIN_DATE';
const END_DATE = 'END_DATE';
const PROVIDER_NAME = 'PROVIDER_NAME';
const DISCOUNT_GOODS = 'DISCOUNT_GOODS';
const GOOD_SET_GOODS = 'GOOD_SET_GOODS';
const IMAGE_URL = 'IMAGE_URL';
const DESCRIPTION = 'DESCRIPTION';
const COMMENT = 'COMMENT';
const PR_DISCOUNT_GOOD = 'PR_DISCOUNT_GOOD';
const TYPE = 'TYPE';
const DROP = 'DROP';
const STAGES = 'STAGES';

export interface SelectedGoods {
  [key: string]: DiscountItem;
}

export interface DiscountItem {
  uid?: string;
  name?: string | null;
  type: DiscountType;
  value: number;
}

interface DiscountState {
  copyDiscount: boolean;
  referenceTemplateUid: string | null;
  name: string | null;
  beginDate: string | null;
  endDate: string | null;
  providerName: string | null;
  discountGoods: SelectedGoods | null;
  goodSetGoods: [] | null;
  imageUrl: string | null;
  description: string | null;
  comment: string | null;
  prDiscountGood: { uid: string, name: string } | null;
  type: string | null;
  stages: PrDiscountStage | null;
}

const moduleDiscount: Module<DiscountState, RootState> = {
  namespaced: true,
  state: (): DiscountState => ({
    copyDiscount: false,
    referenceTemplateUid: null,
    name: null,
    beginDate: null,
    endDate: null,
    providerName: null,
    discountGoods: null,
    goodSetGoods: null,
    imageUrl: null,
    description: null,
    comment: null,
    prDiscountGood: null,
    type: null,
    stages: null,
  }),
  mutations: {
    [COPY_DISCOUNT]: (state: DiscountState, val) => {
      state.copyDiscount = val;
    },
    [UID]: (state: DiscountState, uid) => {
      state.referenceTemplateUid = uid;
    },
    [NAME]: (state: DiscountState, name) => {
      state.name = name;
    },
    [BEGIN_DATE]: (state: DiscountState, beginDate) => {
      state.beginDate = beginDate;
    },
    [END_DATE]: (state: DiscountState, endDate) => {
      state.endDate = endDate;
    },
    [PROVIDER_NAME]: (state: DiscountState, providerName) => {
      state.providerName = providerName;
    },
    [DISCOUNT_GOODS]: (state: DiscountState, discountGoods) => {
      state.discountGoods = discountGoods;
    },
    [GOOD_SET_GOODS]: (state: DiscountState, goodSetGoods) => {
      state.goodSetGoods = goodSetGoods;
    },
    [IMAGE_URL]: (state: DiscountState, url) => {
      state.imageUrl = url;
    },
    [DESCRIPTION]: (state: DiscountState, description) => {
      state.description = description;
    },
    [COMMENT]: (state: DiscountState, comment) => {
      state.comment = comment;
    },
    [PR_DISCOUNT_GOOD]: (state: DiscountState, good) => {
      state.prDiscountGood = good;
    },
    [TYPE]: (state: DiscountState, type) => {
      state.type = type;
    },
    [STAGES]: (state: DiscountState, stages) => {
      state.stages = stages;
    },
    [DROP]: (state: DiscountState) => {
      state.copyDiscount = false;
      state.name = null;
      state.beginDate = null;
      state.endDate = null;
      state.providerName = null;
      state.discountGoods = null;
      state.goodSetGoods = null;
      state.imageUrl = null;
      state.description = null;
      state.comment = null;
      state.prDiscountGood = null;
      state.type = null;
      state.stages = null;
    },
  },
  actions: {
    copyDiscount({ commit }, payload) {
      commit(COPY_DISCOUNT, payload);
    },
    referenceTemplateUid({ commit }, payload) {
      commit(UID, payload);
    },
    name({ commit }, payload) {
      commit(NAME, payload);
    },
    beginDate({ commit }, payload) {
      commit(BEGIN_DATE, payload);
    },
    endDate({ commit }, payload) {
      commit(END_DATE, payload);
    },
    providerName({ commit }, payload) {
      commit(PROVIDER_NAME, payload);
    },
    discountGoods({ commit }, payload) {
      commit(DISCOUNT_GOODS, payload);
    },
    goodSetGoods({ commit }, payload) {
      commit(GOOD_SET_GOODS, payload);
    },
    imageUrl({ commit }, payload) {
      commit(IMAGE_URL, payload);
    },
    description({ commit }, payload) {
      commit(DESCRIPTION, payload);
    },
    comment({ commit }, payload) {
      commit(COMMENT, payload);
    },
    prDiscountGood({ commit }, payload) {
      commit(PR_DISCOUNT_GOOD, payload);
    },
    type({ commit }, payload) {
      commit(TYPE, payload);
    },
    stages({ commit }, payload) {
      commit(STAGES, payload);
    },
    drop({ commit }) {
      commit(DROP);
    },
  },
  getters: {
    copyDiscount: (state: DiscountState) => state.copyDiscount,
    referenceTemplateUid: (state: DiscountState): string | null => state.referenceTemplateUid,
    name: (state: DiscountState) => state.name,
    beginDate: (state: DiscountState) => state.beginDate,
    endDate: (state: DiscountState) => state.endDate,
    providerName: (state: DiscountState) => state.providerName,
    discountGoods: (state: DiscountState) => state.discountGoods,
    goodSetGoods: (state: DiscountState) => state.goodSetGoods,
    imageUrl: (state: DiscountState) => state.imageUrl,
    description: (state: DiscountState) => state.description,
    comment: (state: DiscountState) => state.comment,
    prDiscountGood: (state: DiscountState) => state.prDiscountGood,
    type: (state: DiscountState) => state.type,
    stages: (state: DiscountState) => state.stages,
  },
};

export default moduleDiscount;
