import { ActionTree } from 'vuex';
import RootState from '@/store/models';
import { client } from '@/client';
import { AxiosError, AxiosResponse } from 'axios';
import { DROP_TABLE_DATA, TABLE_DATA } from '@/store/analytics/ratings/mt';
import {
  RatingState,
  DesignedRatingByCategoryReportNode,
} from '@/store/analytics/ratings/models';
import { reasonMessage } from '@/utils/reason-message';
import RatingByCategoryReportNode = Models.Analytics.RatingByCategoryReportNode;

function formatTableData(data: RatingByCategoryReportNode[]): DesignedRatingByCategoryReportNode[] {
  const result: DesignedRatingByCategoryReportNode[] = [];
  data.forEach((node: RatingByCategoryReportNode) => {
    if (node.forRating) {
      result.push(node);
    } else if (!result[result.length - 1] || result[result.length - 1].forRating) {
      result.push({ ...node, rating: [node.rating] });
    } else {
      result[result.length - 1].rating = [...(result[result.length - 1].rating as number[]), node.rating];
    }
  });
  return result;
}

const actions: ActionTree<RatingState, RootState> = {
  async loadTableData({
    commit,
    dispatch,
    rootGetters,
  }): Promise<void> {
    return client.put('/RatingByCategory', {
      fromDate: rootGetters['filter/startDate'],
      toDate: rootGetters['filter/endDate'],
      goodsForRating: rootGetters['filter/goodsUids'],
      foreignGoods: rootGetters['filter/foreignGoodsUids'],
    })
      .then((response: AxiosResponse<RatingByCategoryReportNode[]>) => {
        const data = response.data && response.data.length ? formatTableData(response.data) : [];
        commit(TABLE_DATA, data);
      })
      .catch(async (reason: AxiosError) => {
        if (reason.response?.status === 400 && reason.response?.data
          && reason.response.data.indexOf('присутствует в обоих списках')) {
          dispatch('message/add', {
            status: 'error',
            text: reason.response.data,
          }, { root: true });
        } else dispatch('message/add', reasonMessage(reason), { root: true });
      });
  },
  dropTable({ commit }): void {
    commit(DROP_TABLE_DATA);
  },
};

export default actions;
