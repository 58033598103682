import { RouteRecordRaw } from 'vue-router';

const reviews: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Отзывы на товары' },
    path: '/reviews',
    alias: '/reviews/',
    name: 'Reviews',
    component: () => import(
      /* webpackChunkName: "Reviews" */
      '@/views/reviews/Reviews.vue'
    ),
  },
  {
    meta: { title: 'Отзыв на товар' },
    path: '/reviews/:id',
    alias: '/reviews/:id/',
    name: 'Review',
    component: () => import(
      /* webpackChunkName: "Review" */
      '@/views/reviews/Review.vue'
    ),
  },
];

export default reviews;
