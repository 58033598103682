import { GetterTree } from 'vuex';
import {
  DesignedRatingByCategoryReportNode,
  RatingState,
} from '@/store/analytics/ratings/models';
import RootState from '@/store/models';

const getters: GetterTree<RatingState, RootState> = {
  tableData: (state: RatingState): DesignedRatingByCategoryReportNode[] | null => state.table,
};

export default getters;
