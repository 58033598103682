import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_link = _resolveComponent("side-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_side_link, {
      "route-name": "Home",
      icon: "mark",
      text: "Главная страница"
    }),
    _createVNode(_component_side_link, {
      "route-name": "Products",
      icon: "goods",
      text: "Товары"
    }),
    _createVNode(_component_side_link, {
      "route-name": "Promotions",
      icon: "stock",
      text: "Акции",
      nested: _ctx.promotionsNested
    }, null, 8, ["nested"]),
    (_ctx.analyticsViewsAccess)
      ? (_openBlock(), _createBlock(_component_side_link, {
          key: 0,
          "route-name": "Analytics",
          icon: "analytics",
          text: "Аналитика",
          nested: _ctx.analyticsNested
        }, null, 8, ["nested"]))
      : _createCommentVNode("", true),
    _createVNode(_component_side_link, {
      "route-name": "Reports",
      icon: "reports",
      text: "Отчеты"
    }),
    _createVNode(_component_side_link, {
      "route-name": "Reviews",
      icon: "reviews_menu",
      text: "Отзывы на товары"
    }),
    (_ctx.constructorViewsAccess)
      ? (_openBlock(), _createBlock(_component_side_link, {
          key: 1,
          "route-name": "ConstructorList",
          icon: "constructor_menu",
          text: "Конструктор страниц"
        }))
      : _createCommentVNode("", true)
  ]))
}