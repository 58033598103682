import { RouteRecordRaw } from 'vue-router';

const sideNavigationRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Главная страница' },
    path: '/',
    name: 'Home',
    component: () => import(
      /* webpackChunkName: "products" */
      '@/views/home/Home.vue'
    ),
  },
  {
    meta: { title: 'Модерация' },
    path: '/moderation',
    alias: '/moderation/',
    name: 'Moderation',
    component: () => import(/* webpackChunkName: "products" */ '@/views/PageStub.vue'),
  },
];

export default sideNavigationRoutes;
