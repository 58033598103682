import CookieOptions from '@/models/cookie';

const matcher = (name: string) => new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`);

const cookies = {
  get: (name: string): string | undefined => {
    const matches: RegExpMatchArray | null | undefined = typeof document !== 'undefined'
      ? document.cookie.match(matcher(name))
      : undefined;
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },
  set: (name: string, value: string, options: CookieOptions = {}): void => {
    if (typeof document === 'undefined') return;
    let cookie = `${name}=${encodeURIComponent(value)}`;

    if (options.expires) {
      const date = new Date();
      date.setTime(date.getTime() + options.expires * 60 * 1000);
      cookie += `; Expires=${date.toUTCString()}`;
    }

    if (options.expireDate) {
      const date = new Date(options.expireDate as Date | string | number);
      cookie += `; Expires=${date.toUTCString()}`;
    }
    document.cookie = `${cookie}; path=${options.path || '/'}; SameSite=Lax`;
  },
  del: (name: string, path = '/', domain?: string): void => {
    const value = cookies.get(name);
    if (value) {
      cookies.set(name, value, {
        path,
        domain,
        expires: -1,
      });
    }
  },
};

export default cookies;
