import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "side-nav__sub-links"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.nested && _ctx.nested.length)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: _normalizeClass(["link side-nav__link", {'router-link-active': _ctx.isOpen, 'side-nav__link--active': _ctx.isActive}]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop"]))
        }, [
          _createVNode(_component_icon_svg, {
            class: "side-nav__link-icon",
            symbol: _ctx.icon || 'nav_placeholder'
          }, null, 8, ["symbol"]),
          _createElementVNode("span", {
            class: "side-nav__link-text",
            innerHTML: _ctx.text
          }, null, 8, _hoisted_1),
          _createVNode(_component_icon_svg, {
            class: "side-nav__link-arrow",
            symbol: "arrow"
          })
        ], 2))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: {name: _ctx.routeName},
          class: "link side-nav__link"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_svg, {
              class: "side-nav__link-icon",
              symbol: _ctx.icon || 'nav_placeholder'
            }, null, 8, ["symbol"]),
            _createElementVNode("span", {
              class: "side-nav__link-text",
              innerHTML: _ctx.text
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["to"])),
    (_ctx.nested && _ctx.nested.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nested, (n) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: n.routeName
            }, [
              (!n.hide)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: {name: n.routeName},
                    class: "link side-nav__sub-link",
                    innerHTML: n.text
                  }, null, 8, ["to", "innerHTML"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}