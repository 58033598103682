import { createStore } from 'vuex';
import moduleUser from '@/store/user';
import RootState from '@/store/models';
import { AUTH_TOKEN_NAME, setAuthorization } from '@/client';
import moduleMessage from '@/store/messenger';
import moduleSidebar from '@/store/sidebar';
import moduleSales from '@/store/analytics/sales';
import moduleSearchQueries from '@/store/analytics/search-queries';
import moduleRatings from '@/store/analytics/ratings';
import moduleFavorites from '@/store/favorites';
import moduleDiscount from '@/store/discount';
import moduleFilter from '@/store/filter';
import cookies from '@/utils/cookie';

const debug = process.env.NODE_ENV !== 'production';

export default createStore<RootState>({
  actions: {
    start: async ({ dispatch }) => {
      const token: string | undefined = cookies.get(AUTH_TOKEN_NAME);
      setAuthorization(token);
      await dispatch('user/token', token);
      if (token && cookies.get('userAgreement')) await dispatch('user/load');
      else await dispatch('user/logout');
    },
  },
  modules: {
    user: moduleUser,
    message: moduleMessage,
    sidebar: moduleSidebar,
    sales: moduleSales,
    searchQueries: moduleSearchQueries,
    ratings: moduleRatings,
    discount: moduleDiscount,
    favorites: moduleFavorites,
    filter: moduleFilter,
  },
  strict: debug,
});
