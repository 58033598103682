import { readonly, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { client } from '@/client';
import { AxiosError, AxiosResponse } from 'axios';
import { reasonMessage } from '@/utils/reason-message';

interface UseWhatsNew {
  newsCount: Ref<number>;
  getNewsCount: () => Promise<void>;
}

const newsCount = ref<number>(0);

function useWhatsNew(): UseWhatsNew {
  const store = useStore();

  async function getNewsCount(): Promise<void> {
    return client.get<number>('/Notification/UnreadCount').then((response: AxiosResponse<number>) => {
      newsCount.value = response.data;
    }).catch(async (reason: AxiosError) => store.dispatch('message/add', reasonMessage(reason)));
  }

  return {
    newsCount: readonly(newsCount),
    getNewsCount,
  };
}

export {
  useWhatsNew,
};
