import { ActionTree } from 'vuex';
import RootState from '@/store/models';
import { FavoritesState } from '@/store/favorites/models';
import {
  FAVORITES,
  LOADING_FAVORITES,
  DROP_FAVORITES,
  FAVORITES_COUNT,
  LOADING_DELETING,
  SHOW_DELETE_POPUP,
  SELECTED_FAVORITE,
} from '@/store/favorites/mt';
import { client } from '@/client';
import { AxiosError, AxiosResponse } from 'axios';
import { reasonMessage } from '@/utils/reason-message';
import ResponsePage = Models.Common.ResponsePage;
import FavoriteRequestInfo = Models.Favorites.FavoriteRequestInfo;

const actions: ActionTree<FavoritesState, RootState> = {
  loadFavorites: async ({
    commit,
    dispatch,
  }, payload: { type: string, page: number }): Promise<void> => {
    commit(LOADING_FAVORITES, true);
    await client.get(
      '/Favorites/List',
      { params: { type: payload.type, page: payload.page, pageSize: 5 } },
    )
      .then((response: AxiosResponse<ResponsePage<FavoriteRequestInfo>>) => {
        commit(FAVORITES, response.data);
      })
      .catch(async (reason: AxiosError) => {
        await dispatch('message/add', reasonMessage(reason), { root: true });
      });
    commit(LOADING_FAVORITES, false);
  },
  loadFavoritesCount: async ({ commit, dispatch }, type: string): Promise<void> => {
    await client.get('/Favorites/Quantity', { params: { type } })
      .then((response: AxiosResponse<number>) => {
        commit(FAVORITES_COUNT, response.data || 0);
      })
      .catch(async (reason: AxiosError) => {
        await dispatch('message/add', reasonMessage(reason), { root: true });
      });
  },
  deleteFavorite: async ({
    commit,
    dispatch,
  }, payload: { type: string, favoriteUid: string }): Promise<void> => {
    commit(LOADING_DELETING, payload.favoriteUid);
    await client.delete('/Favorites',
      { params: { type: payload.type, favoriteUid: payload.favoriteUid } })
      .then(async () => {
        await dispatch('message/add', { text: 'Фильтр успешно удалён', status: 'success' },
          { root: true });
      })
      .catch(async (reason: AxiosError) => {
        await dispatch('message/add', reasonMessage(reason), { root: true });
      });
    commit(LOADING_DELETING, null);
  },
  showDeletePopup({ commit }, payload: { name: string, type: string, favoriteUid: string } | null): void {
    commit(SHOW_DELETE_POPUP, payload);
  },
  selectedFavorite({ commit }, payload: { name: string, type: string, favoriteUid: string } | null): void {
    commit(SELECTED_FAVORITE, payload);
  },
  dropFavorites({ commit }): void {
    commit(DROP_FAVORITES);
  },
  dropFavoritesCount({ commit }): void {
    commit(FAVORITES_COUNT, 0);
  },
};

export default actions;
