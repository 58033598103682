import { GetterTree, Module } from 'vuex';
import RootState from '@/store/models';
import { SalesState, ChartRange, AnalyticsChartBar } from '@/store/analytics/sales/models';
import { client } from '@/client';
import { ProductRecord, SelectedProductRecord } from '@/components/analytics/sales/products/models';
import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import getMax from '@/components/analytics/sales/chart/get-max';
import { reasonMessage } from '@/utils/reason-message';
import IPharmSalesReportNode = Models.KcsQuery.IPharmSalesReportNode;
import ProviderInfo = Models.Provider.ProviderInfo;
import GoodInfo = Models.Good.GoodInfo;
import GeoSalesReportNode = Models.Analytics.GeoSalesReportNode;
import GeoSalesParams = Models.Analytics.GeoSalesParams;

const CHART_DATA = 'CHART_DATA';
const MAP_DATA = 'MAP_DATA';
const LOADING = 'LOADING';
const CHART_PREVIEW = 'CHART_PREVIEW';
const RANGE = 'RANGE';
const PROVIDERS = 'PROVIDERS';
const DROP = 'DROP';
const GOODS = 'GOODS';
const SELECT_PRODUCTS = 'SELECT_PRODUCTS';
const PRODUCT_RECORDS_PUSH = 'PRODUCT_RECORDS_PUSH';
const DROP_PRODUCT_RECORDS = 'DROP_PRODUCT_RECORDS';
const SALE_TYPES = 'SALE_TYPES';
const DATA_SOURCE = 'DATA_SOURCE';

const int = (v: string): number => Number.parseInt(v, 10);

const convertPeriod = (p: string): Moment => {
  const [day, month, year] = p.split('.');
  return moment({ day: int(day), month: int(month) - 1, year: int(year) }, undefined, 'ru');
};

const nullable = (val?: number | null) => val || 0;

const sumBar = (a: AnalyticsChartBar, b: AnalyticsChartBar) => ({
  ...a,
  amount: nullable(a.amount) + nullable(b.amount),
  priceMarkAmount: nullable(a.priceMarkAmount) + nullable(b.priceMarkAmount),
  goodSetAmount: nullable(a.goodSetAmount) + nullable(b.goodSetAmount),
  promoAmount: nullable(a.promoAmount) + nullable(b.promoAmount),
  fullPriceAmount: nullable(a.fullPriceAmount) + nullable(b.fullPriceAmount),
  periodEnd: b.periodEnd || b.period,
});

const moduleSales: Module<SalesState, RootState> = {
  namespaced: true,
  state: () => ({
    dataSource: [],
    chartData: [],
    mapData: [],
    chartPreview: [],
    providers: [],
    selectedProducts: {},
    productRecords: [],
    range: {
      start: 0,
      end: 0,
    },
    loading: false,
    saleTypes: {
      fullPriceAmount: true,
      priceMarkAmount: true,
      goodSetAmount: true,
      promoAmount: true,
    },
  }),
  actions: {
    setRange({ commit, state }, payload: ChartRange) {
      commit(RANGE, payload);
      commit(CHART_DATA, state.chartPreview.slice(state.range.start, state.range.end));
    },
    async load({ commit, dispatch, rootGetters }): Promise<void> {
      if (!rootGetters['filter/startDate'] || !rootGetters['filter/endDate']
        || !rootGetters['filter/goodsUids'].length || !rootGetters['filter/detalisation'].length) {
        return undefined;
      }
      commit(DATA_SOURCE, []);
      commit(CHART_DATA, []);
      commit(CHART_PREVIEW, []);
      commit(LOADING, true);
      const f = (date?: string) => moment(date).format('YYYY-MM-DD');
      const goodsCount: number = rootGetters['filter/goodsUids']?.length || 0;
      return client.put('/IPharmSales', {
        beginDate: f(rootGetters['filter/startDate']),
        endDate: f(rootGetters['filter/endDate']),
        goods: rootGetters['filter/goodsUids'],
        detalisation: rootGetters['filter/detalisation'],
      })
        .then(async (response: AxiosResponse<IPharmSalesReportNode[]>) => {
          if (!response.data.length) {
            await dispatch('message/add', {
              status: 'info',
              text: 'Поиск не дал результатов',
            }, { root: true });
          } else {
            commit(DATA_SOURCE, response.data);
            await dispatch('composeChartData');
          }
        })
        .catch(async (reason: AxiosError) => {
          commit(CHART_DATA, []);
          commit(CHART_PREVIEW, []);
          const { code } = reason;
          const { status } = reason.response || {};
          let msg: {status: string | number, text?: string} = {
            text: '',
            status: 'error',
          };
          if (status === 400 || code === '400') {
            msg.text = goodsCount > 1
              ? 'Максимальный период 3 месяца, пожалуйста, измените фильтры.'
              : 'Максимальный период 5 лет, пожалуйста, измените фильтры.';
          } else {
            msg = reasonMessage(reason);
          }
          await dispatch('message/add', msg, { root: true });
        })
        .finally(() => {
          commit(LOADING, false);
        });
    },
    async composeChartData({
      commit, dispatch, state, rootGetters,
    }): Promise<void> {
      if (!state.dataSource.length) return;

      const dtz = rootGetters['filter/detalisation']?.toLowerCase() as 'day' | 'month' | 'quarter' | undefined;

      const data: AnalyticsChartBar[] = state.dataSource
        .reduce((result: AnalyticsChartBar[], bar: IPharmSalesReportNode) => {
          if (!bar.period) return result;
          const converted: AnalyticsChartBar = {
            ...bar,
            period: convertPeriod(bar.period),
            periodEnd: convertPeriod(bar.period),
          };
          if (!dtz || dtz === 'day') return [...result, converted];

          // пустой результат
          if (!result.length) return [converted];

          const prev: AnalyticsChartBar = result[result.length - 1];

          // прошлый бар того же месяца/квартала, что и следующий
          if (converted.period.isSame(prev.period, dtz)) {
            result[result.length - 1] = sumBar(prev, converted);
            return result;
          }
          // прошлый бар следующего месяца/квартала, что и следующий
          return [...result, converted];
        }, []);

      dispatch('setRange', { start: 0, end: data.length });

      commit(CHART_DATA, data);
      commit(CHART_PREVIEW, data);
    },
    async loadMapData({ commit, dispatch, rootState }, payload: GeoSalesParams): Promise<void> {
      return client.put('/GeoSales', payload)
        .then(async (response: AxiosResponse<GeoSalesReportNode[]>) => {
          commit(MAP_DATA, response.data);
          if (!response.data.length) {
            // @ts-ignore
            if (!Object.keys(rootState.message?.messages).length) {
              await dispatch('message/add', {
                status: 'info',
                text: 'Поиск не дал результатов',
              }, { root: true });
            }
          }
        })
        .catch(async (reason: AxiosError) => {
          commit(MAP_DATA, []);
          await dispatch('message/add', reasonMessage(reason), { root: true });
        });
    },
    selectProducts({ commit }, payload: { [uid: string]: SelectedProductRecord }) {
      commit(SELECT_PRODUCTS, payload);
    },
    dropProductRecords({ commit }) {
      commit(DROP_PRODUCT_RECORDS);
    },
    productRecordsPush({ commit }, payload: { provider: ProviderInfo, goods: GoodInfo[] }) {
      commit(PRODUCT_RECORDS_PUSH, payload);
    },
    async recordProducts({ commit, dispatch }, payload: { goodsUids: string[] }): Promise<void> {
      if (!payload.goodsUids.length) return;
      commit(GOODS, payload.goodsUids);
      if (payload.goodsUids.length === 1) {
        await dispatch(
          'message/add',
          {
            status: 'info',
            text: 'Доступен выбор периода сроком до 5 лет',
          },
          { root: true },
        );
      }
      if (payload.goodsUids.length > 1) {
        await dispatch(
          'message/add',
          {
            status: 'info',
            text: 'Доступен выбор периода сроком до 3 месяцев',
          },
          { root: true },
        );
      }
    },
    async addProduct({
      commit, dispatch, state, rootGetters,
    }, payload: { good: GoodInfo, provider: ProviderInfo }): Promise<void> {
      if (!payload.good.uid || !payload.provider.uid) return;
      const record = { ...state.selectedProducts[payload.provider.uid] } || {};

      const filterGoods = rootGetters['filter/goods'] || [];
      if (!filterGoods.length) {
        await dispatch(
          'message/add',
          {
            status: 'info',
            text: 'Доступен выбор периода сроком до 5 лет',
          },
          { root: true },
        );
      } else if (filterGoods.length === 1) {
        await dispatch(
          'message/add',
          {
            status: 'info',
            text: 'Доступен выбор периода сроком до 3 месяцев',
          },
          { root: true },
        );
      } else if (filterGoods.length >= 100) return;

      const goodIsAlreadyInFilter = !!rootGetters['filter/goods']
        ?.find((good: GoodInfo) => good.uid === payload.good.uid);
      if (!goodIsAlreadyInFilter) commit(GOODS, [...filterGoods, payload.good.uid]);

      commit(SELECT_PRODUCTS, {
        ...state.selectedProducts,
        [payload.provider.uid]: {
          provider: payload.provider,
          goods: {
            ...record.goods,
            [payload.good.uid]: payload.good,
          },
        },
      });
    },
    removeProduct({ commit, state, rootGetters }, payload: { provider: string, good: string }): void {
      const goods = { ...state.selectedProducts[payload.provider]?.goods };
      delete goods[payload.good];

      const filterGoods = (rootGetters['filter/goods'] || []).filter((good: GoodInfo) => good.uid !== payload.good);
      commit(GOODS, [...filterGoods]);

      commit(SELECT_PRODUCTS, {
        ...state.selectedProducts,
        [payload.provider]: {
          ...state.selectedProducts[payload.provider],
          goods,
        },
      });
    },
    providers({ commit }, providers: ProviderInfo[]) {
      commit(PROVIDERS, providers);
    },
    clear({ commit }) {
      commit(DROP);
    },
  },
  mutations: {
    [LOADING]: (state: SalesState, payload: boolean) => {
      state.loading = payload;
    },
    [RANGE]: (state: SalesState, payload: ChartRange) => {
      state.range = payload;
    },
    [DATA_SOURCE]: (state: SalesState, payload: IPharmSalesReportNode[]) => {
      state.dataSource = payload;
    },
    [CHART_DATA]: (state: SalesState, payload: AnalyticsChartBar[]) => {
      state.chartData = payload;
    },
    [MAP_DATA]: (state: SalesState, payload: GeoSalesReportNode[]) => {
      state.mapData = payload;
    },
    [CHART_PREVIEW]: (state: SalesState, payload: AnalyticsChartBar[]) => {
      state.chartPreview = payload;
    },
    [PROVIDERS]: (state: SalesState, providers: ProviderInfo[]) => {
      state.providers = providers;
    },
    [SELECT_PRODUCTS]: (state: SalesState, payload: { [uid: string]: SelectedProductRecord }) => {
      state.selectedProducts = payload;
    },
    [PRODUCT_RECORDS_PUSH]: (state: SalesState, payload: { provider: ProviderInfo, goods: GoodInfo[] }) => {
      state.productRecords = [...state.productRecords, payload];
    },
    [DROP_PRODUCT_RECORDS]: (state: SalesState) => {
      state.productRecords = [];
    },
    [DROP]: (state: SalesState) => {
      state.chartData = [];
      state.providers = [];
      state.selectedProducts = {};
      state.productRecords = [];
    },
    [SALE_TYPES]: (state: SalesState, payload: { [key: string]: boolean }) => {
      state.saleTypes = payload;
    },
  },
  getters: {
    range: (state: SalesState) => state.range,
    loading: (state: SalesState) => state.loading,
    chartData: (state: SalesState): AnalyticsChartBar[] => state.chartData,
    mapData: (state: SalesState): GeoSalesReportNode[] => state.mapData,
    chartPreview: (state: SalesState): AnalyticsChartBar[] => state.chartPreview,
    chartGraphHeight: (state: SalesState, getters) => getMax(getters.chartData),
    previewGraphHeight: (state: SalesState, getters) => getMax(getters.chartPreview),
    providers: (state: SalesState): ProviderInfo[] => state.providers,
    productRecords: (state: SalesState): ProductRecord[] => state.productRecords,
    selectedProducts: (state: SalesState): { [uid: string]: SelectedProductRecord } => state.selectedProducts,
    goodsCount: (
      state: SalesState, getters, rootState: GetterTree<RootState, SalesState>,
    ): number => rootState['filter/goods']?.length || 0,
    productsCount: (state: SalesState): number => Object
      .values(state.selectedProducts)
      .reduce((result: number, next: SelectedProductRecord) => result + Object.keys(next.goods).length, 0),
    isFilterDisabled: (state, getters, rootState): boolean => {
      if (!getters.goodsCount) return true;
      const { startDate, endDate } = rootState['filter/filters'];
      if (!startDate) return true;
      if (!endDate) return true;
      if (!getters.productsCount) return true;

      const d1 = moment(startDate);
      const d2 = moment(endDate);

      const diff = (unit: 'days' | 'years' | 'months') => d2.diff(d1, unit);

      const diffDays = diff('days');
      const diffYears = diff('years');
      const diffMonth = diff('months');

      const lessThenOneDay: boolean = diffDays < 1;
      if (lessThenOneDay) return true;

      const moreThenFiveYears: boolean = diffYears > 5;
      const moreThenThreeMonth: boolean = diffMonth > 3;

      return getters.productsCount > 1 ? moreThenThreeMonth : moreThenFiveYears;
    },
    saleTypes: (state): { [key: string]: boolean } => state.saleTypes,
  },
};

export default moduleSales;
