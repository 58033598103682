import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recovery_password = _resolveComponent("recovery-password")!
  const _component_auth_form = _resolveComponent("auth-form")!
  const _component_auth_layout = _resolveComponent("auth-layout")!

  return (_openBlock(), _createBlock(_component_auth_layout, null, {
    default: _withCtx(() => [
      (_ctx.$route.hash === '#reset')
        ? (_openBlock(), _createBlock(_component_recovery_password, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({hash: undefined})))
          }))
        : (_openBlock(), _createBlock(_component_auth_form, { key: 1 }))
    ]),
    _: 1
  }))
}