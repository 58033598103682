import { AuthRequest } from '@/store/user/models';
import { client, setAuthorization } from '@/client';
import axios, { AxiosResponse, Canceler } from 'axios';
import cookies from '@/utils/cookie';
import LkpUserDetails = Models.LkpUser.LkpUserDetails;
import ResetPasswordRequest = Models.Auth.ResetPasswordRequest;
import UpdatePasswordRequest = Models.Auth.UpdatePasswordRequest;
import RecoveryRequest = Models.Auth.RecoveryRequest;
import AuthInfo = Models.Auth.AuthInfo;

const url = {
  auth: '/Auth',
  updatePassword: '/Auth/UpdatePassword',
  restorePassword: '/Auth/ResetPassword',
  restoreRequest: '/Auth/Recovery',
  info: '/LkpUser',
};

const { CancelToken } = axios;

async function authorization(request: AuthRequest): Promise<string> {
  if (!request.login || !request.code) {
    throw new Error(`[POST: ${url.auth}]: No login or password`);
  }
  return client.post(url.auth, request)
    .then((response: AxiosResponse<AuthInfo>) => {
      const { token, lifeTime, permissionsHashCode } = response.data;
      cookies.set('x-user-hash', permissionsHashCode);
      setAuthorization(token, lifeTime);
      return token;
    });
}

let cancel: Canceler | undefined;

function cancelGetInfo(message: string): void {
  if (cancel) cancel(message);
  cancel = undefined;
}

async function getUserInfo(): Promise<LkpUserDetails> {
  return client.get(
    url.info,
    {
      cancelToken: new CancelToken((c: Canceler) => {
        cancel = c;
      }),
    },
  )
    .then((response: AxiosResponse<LkpUserDetails>) => response.data);
}

async function updatePassword(payload: UpdatePasswordRequest): Promise<void> {
  if (!payload.newPassword || !payload.currentPassword) {
    throw new Error(`[PUT: ${url}]: bad request payload`);
  }
  return client.post(url.updatePassword, payload);
}

async function restorePassword(request: ResetPasswordRequest): Promise<void> {
  return client.post(url.restorePassword, request);
}

async function restoreRequest(request: RecoveryRequest): Promise<void> {
  return client.post(url.restoreRequest, request);
}

export {
  cancelGetInfo,
  getUserInfo,
  updatePassword,
  restorePassword,
  restoreRequest,
  authorization,
};
