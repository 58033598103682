
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

interface Data {
  hidePassword: boolean;
}

export default defineComponent({
  name: 'InputPassword',
  components: { IconSvg },
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data(): Data {
    return {
      hidePassword: true,
    };
  },
  methods: {
    toggleType(): void {
      this.$data.hidePassword = !this.$data.hidePassword;
    },
  },
});
