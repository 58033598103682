import { Module } from 'vuex';
import RootState from '@/store/models';
import { FilterState } from '@/store/filter/models';
import { Option } from '@/components/inputs/models';
import TemplateStatus = Models.GoodSet.TemplateStatus;
import GoodInfo = Models.Good.GoodInfo;

const SHOW_FILTER = 'SHOW_FILTER';
const SHOW_GOODS_SELECTOR = 'SHOW_GOODS_SELECTOR';
const START_DATE = 'START_DATE';
const END_DATE = 'END_DATE';
const STATUSES = 'STATUSES';
const DROP = 'DROP';
const GOODS = 'GOODS';
const FOREIGN_GOODS = 'FOREIGN_GOODS';
const BRAND = 'BRAND';
const KEYWORDS = 'KEYWORDS';
const APPLY_INDICATOR = 'APPLY_INDICATOR';
const RATING = 'RATING';
const GOODS_REVIEWS_STATUS = 'GOODS_REVIEWS_STATUS';
const SEARCH_PHRASE = 'SEARCH_PHRASE';
const PROVIDERS = 'PROVIDERS';
const DETALISATION = 'DETALISATION';

const moduleFilter: Module<FilterState, RootState> = {
  namespaced: true,
  state: (): FilterState => ({
    showFilter: false,
    showGoodsSelector: false,
    applyIndicator: false,
    filters: {
      startDate: '',
      endDate: '',
      statuses: [],
      goods: [],
      foreignGoods: [],
      keywords: [],
      rating: 'All',
      goodsReviewsStatus: 'All',
      brand: '',
      brandName: '',
      searchPhrase: '',
      providers: [],
      detalisation: '',
    },
  }),
  mutations: {
    [SHOW_FILTER]: (state: FilterState, val: boolean) => {
      state.showFilter = val;
    },
    [SHOW_GOODS_SELECTOR]: (state: FilterState, val: boolean) => {
      state.showFilter = false;
      state.showGoodsSelector = val;
    },
    [START_DATE]: (state: FilterState, val: string) => {
      state.filters.startDate = val;
    },
    [END_DATE]: (state: FilterState, val: string) => {
      state.filters.endDate = val;
    },
    [STATUSES]: (state: FilterState, val: TemplateStatus[]) => {
      state.filters.statuses = val;
    },
    [GOODS]: (state: FilterState, val: GoodInfo[]) => {
      state.filters.goods = val;
    },
    [FOREIGN_GOODS]: (state: FilterState, val: GoodInfo[]) => {
      state.filters.foreignGoods = val;
    },
    [BRAND]: (state: FilterState, payload?:{name?:string, uid:string}) => {
      state.filters.brand = payload?.uid || '';
      state.filters.brandName = payload?.name || '';
    },
    [KEYWORDS]: (state: FilterState, val: string[]) => {
      state.filters.keywords = val;
    },
    [APPLY_INDICATOR]: (state: FilterState, val: boolean) => {
      state.applyIndicator = val;
    },
    [RATING]: (state: FilterState, val: string) => {
      state.filters.rating = val;
    },
    [GOODS_REVIEWS_STATUS]: (state: FilterState, val: string) => {
      state.filters.goodsReviewsStatus = val;
    },
    [SEARCH_PHRASE]: (state: FilterState, val: string) => {
      state.filters.searchPhrase = val;
    },
    [PROVIDERS]: (state: FilterState, providers: Option[]) => {
      state.filters.providers = providers;
    },
    [DETALISATION]: (state: FilterState, val: string) => {
      state.filters.detalisation = val;
    },
    [DROP]: (state: FilterState) => {
      state.filters.startDate = '';
      state.filters.endDate = '';
      state.filters.statuses = [];
      state.filters.goods = [];
      state.filters.foreignGoods = [];
      state.filters.brand = '';
      state.filters.brandName = '';
      state.filters.keywords = [];
      state.filters.rating = 'All';
      state.filters.goodsReviewsStatus = 'All';
      state.filters.searchPhrase = '';
      state.filters.providers = [];
      state.filters.detalisation = '';
    },
  },
  actions: {
    showFilter({ commit }, payload) {
      commit(SHOW_FILTER, payload);
    },
    showGoodsSelector({ commit }, payload) {
      commit(SHOW_GOODS_SELECTOR, payload);
    },
    startDate({ commit, dispatch }, payload) {
      commit(START_DATE, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    endDate({ commit, dispatch }, payload) {
      commit(END_DATE, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    statuses({ commit, dispatch }, payload) {
      commit(STATUSES, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    goods({ commit, dispatch }, payload) {
      commit(GOODS, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    foreignGoods({ commit, dispatch }, payload) {
      commit(FOREIGN_GOODS, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    brand({ commit }, payload:{name:string, uid:string}) {
      commit(BRAND, payload);
    },
    keywords({ commit, dispatch }, payload: string[]) {
      commit(KEYWORDS, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    applyIndicator({ commit }, payload) {
      commit(APPLY_INDICATOR, payload);
    },
    rating({ commit, dispatch }, payload) {
      commit(RATING, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    goodsReviewsStatus({ commit, dispatch }, payload) {
      commit(GOODS_REVIEWS_STATUS, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    searchPhrase({ commit, dispatch }, payload: string) {
      commit(SEARCH_PHRASE, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    providers({ commit, dispatch }, payload: Option[]) {
      commit(PROVIDERS, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    detalisation({ commit, dispatch }, payload) {
      commit(DETALISATION, payload);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
    drop({ commit, dispatch }) {
      commit(DROP);
      dispatch('favorites/selectedFavorite', null, { root: true });
    },
  },
  getters: {
    showFilter: (state: FilterState) => state.showFilter,
    showGoodsSelector: (state: FilterState) => state.showGoodsSelector,
    filters: (state: FilterState) => state.filters,
    startDate: (state: FilterState) => state.filters.startDate,
    endDate: (state: FilterState) => state.filters.endDate,
    statuses: (state: FilterState) => state.filters.statuses,
    goods: (state: FilterState) => state.filters.goods,
    foreignGoods: (state: FilterState) => state.filters.foreignGoods,
    keywords: (state: FilterState): string[] => state.filters.keywords,
    goodsUids: (state: FilterState) => state.filters.goods
      .reduce((result: string[], current: GoodInfo) => [...result, current.uid || ''], []) || [],
    foreignGoodsUids: (state: FilterState) => state.filters.foreignGoods
      .reduce((result: string[], current: GoodInfo) => [...result, current.uid || ''], []) || [],
    disableButtons: (state: FilterState) => Boolean(!Object.values(state.filters)
      .find((val: any) => String(val).length && val !== 'All')),
    applyIndicator: (state: FilterState) => state.applyIndicator,
    selectedCounterFilters: (state: FilterState): number => {
      let counter = 0;
      const {
        startDate, endDate, goods, foreignGoods, brand, statuses, keywords, rating, goodsReviewsStatus,
        searchPhrase, providers, detalisation,
      } = state.filters;
      if (String(startDate || '').length || String(endDate || '').length) counter += 1;
      if (goods.length || foreignGoods.length) counter += 1;
      if (brand) counter += 1;
      if (statuses.length) counter += 1;
      if (keywords.length) counter += 1;
      if (rating !== 'All') counter += 1;
      if (searchPhrase.length) counter += 1;
      if (goodsReviewsStatus !== 'All') counter += 1;
      if (providers.length) counter += 1;
      if (detalisation.length) counter += 1;
      return counter;
    },
    rating: (state: FilterState) => state.filters.rating,
    goodsReviewsStatus: (state: FilterState) => state.filters.goodsReviewsStatus,
    searchPhrase: (state: FilterState): string => state.filters.searchPhrase,
    providers: (state: FilterState): string[] => state.filters.providers.map((r: Option) => r.value),
    providersNames: (state: FilterState): string[] => state.filters.providers.map((r: Option) => r.name),
    detalisation: (state: FilterState) => state.filters.detalisation,
  },
};

export default moduleFilter;
