
import { RouteLocation, RouteLocationMatched, RouteLocationRaw } from 'vue-router';
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

export interface Crumb {
  label: string;
  route?: RouteLocationRaw;
}

export default defineComponent({
  name: 'Crumbs',
  components: { IconSvg },
  created(): void {
    this.updateCrumbs();
  },
  data(): { routes: Crumb[] } {
    return {
      routes: [],
    };
  },
  props: {
    crumbs: {
      required: false,
    },
    showHome: {
      required: false,
      default: () => true,
    },
  },
  watch: {
    crumbs(): void {
      this.updateCrumbs();
    },
  },
  methods: {
    updateCrumbs(): void {
      let routes: Crumb[] = [{
        label: 'Главная',
        route: this.$route,
      }];

      routes = this.$props.crumbs
        ? routes.concat(this.$props.crumbs as Crumb[])
        : routes.concat(this.$route.matched
          .map((rec: RouteLocationMatched) => this.fetchRoute(rec)));

      this.routes = routes.slice(1);
    },
    fetchRoute(rec: RouteLocationMatched): Crumb {
      let route: RouteLocation | undefined = !rec.name
        ? undefined
        : this.$router.resolve({ name: rec.name });

      if (!route?.name || route.name === 'NotFound') route = this.$router.resolve(rec.path);

      return {
        route: { name: route?.name || 'not-found' },
        label: (rec.meta?.title || route.meta?.title || route.name) as string,
      };
    },
  },
});
