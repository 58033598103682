import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input-field__label" }
const _hoisted_2 = { class: "input-password__wrap" }
const _hoisted_3 = ["type", "readonly", "value", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "input-field__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-field input-password", {'input-field__error': _ctx.error}])
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.label || 'Введите пароль') + " ", 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          class: "input-field__input",
          ref: "input",
          type: _ctx.hidePassword ? 'password' : 'text',
          name: "password",
          readonly: _ctx.readonly ? '' : null,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur'))),
          disabled: _ctx.$props.disabled
        }, null, 40, _hoisted_3),
        _createVNode(_component_icon_svg, {
          symbol: _ctx.hidePassword ? 'show' : 'hide',
          onClick: _ctx.toggleType
        }, null, 8, ["symbol", "onClick"])
      ])
    ]),
    ((_ctx.error || _ctx.showHint) && _ctx.hint)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.hint), 1))
      : _createCommentVNode("", true)
  ], 2))
}