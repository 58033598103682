import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "header-logo",
    to: {name: 'Home'}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_svg, {
        class: "header-logo__logo",
        symbol: $props.isLight ? 'logo_light' : 'logo'
      }, null, 8, ["symbol"])
    ]),
    _: 1
  }))
}