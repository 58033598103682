
import { defineComponent } from 'vue';
import InputEmail from '@/components/inputs/InputEmail.vue';
import ButtonComponent from '@/components/buttons/Button.vue';
import { restoreRequest } from '@/store/user/api';
import RecoverySuccess from '@/components/auth/RecoverySuccess.vue';
import { AxiosError } from 'axios';
import { reasonMessage } from '@/utils/reason-message';
import { mapActions } from 'vuex';

interface ComponentData {
  email: string;
  loading: boolean;
  error: boolean;
  success: boolean;
}

export default defineComponent({
  name: 'RecoveryPassword',
  components: {
    RecoverySuccess,
    ButtonComponent,
    InputEmail,
  },
  data(): ComponentData {
    return {
      email: '',
      loading: false,
      error: false,
      success: false,
    };
  },
  computed: {
    disabled(): boolean {
      // eslint-disable-next-line max-len
      const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return !re.test(this.email.toLowerCase());
    },
  },
  methods: {
    ...mapActions('message', {
      addMessage: 'add',
    }),
    restore(): Promise<void> {
      this.loading = true;
      this.dropError();
      return restoreRequest({ email: this.email }).then(() => {
        this.success = true;
      }).catch(async (reason: AxiosError) => {
        this.error = true;
        await this.addMessage(reasonMessage(reason));
      }).finally(() => {
        this.loading = false;
      });
    },
    dropError() {
      if (this.error) this.error = false;
    },
  },
  watch: {
    email() {
      this.dropError();
    },
  },
});
