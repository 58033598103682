import { MutationTree } from 'vuex';
import {
  DROP_TABLE_DATA,
  TABLE_DATA,
} from '@/store/analytics/ratings/mt';
import { RatingState } from '@/store/analytics/ratings/models';
import RatingByCategoryReportNode = Models.Analytics.RatingByCategoryReportNode;

const mutations: MutationTree<RatingState> = {
  [TABLE_DATA]: (state: RatingState, payload: RatingByCategoryReportNode[]) => {
    state.table = payload;
  },
  [DROP_TABLE_DATA]: (state: RatingState) => {
    state.table = null;
  },
};

export default mutations;
