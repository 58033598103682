const filterPluralize = (value: number, n1 = '', n2 = '', s5?: string): string => {
  const n5 = s5 !== undefined ? s5 : n2;
  let num = Math.abs(value);
  num %= 100;
  if (num >= 5 && num <= 20) return n5;
  num %= 10;
  if (num === 1) return n1;
  return num >= 2 && num <= 4 ? n2 : n5;
};

export default filterPluralize;
