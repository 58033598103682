
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import SideLink from '@/components/layout/aside/SideLink.vue';
import { JWT } from '@/utils/jwt';

export interface NestedRoute {
  routeName: string;
  text: string;
  hide?: boolean;
}

export default defineComponent({
  name: 'SideNavigation',
  components: {
    SideLink,
  },
  data() {
    return {
      productCardsViewsAccess: false,
      constructorViewsAccess: false,
      analyticsViewsAccess: false,
      analyticsSalesAccess: false,
      analyticsSearchAccess: false,
      analyticsRatingAccess: false,
    };
  },
  beforeMount() {
    this.updateAccess();
  },
  computed: {
    ...mapState('user', ['token']),
    promotionsNested(): NestedRoute[] {
      return [
        { routeName: 'Discount', text: 'Скидки' },
        { routeName: 'GoodSets', text: 'Комплекты' },
        { routeName: 'PrDiscounts', text: 'Прогрессивные скидки' },
        { routeName: 'Promocodes', text: 'Промокоды' },
        { routeName: 'PromotionsCalendar', text: 'Календарь акций' },
      ];
    },
    analyticsNested(): NestedRoute[] {
      return [
        {
          routeName: 'Sales',
          text: 'Продажи',
          hide: !this.analyticsSalesAccess,
        },
        {
          routeName: 'SearchQueries',
          text: 'Поисковые запросы',
          hide: !this.analyticsSearchAccess,
        },
        {
          routeName: 'RatingFilters',
          text: 'Рейтинг в категории',
          hide: !this.analyticsRatingAccess,
        },
        {
          routeName: 'ProductsReport',
          text: 'Просмотры карточек<br/> товаров',
          hide: !this.productCardsViewsAccess,
        },
      ];
    },
  },
  watch: {
    token() {
      this.updateAccess();
    },
  },
  methods: {
    updateAccess() {
      const token = JWT.decode(this.token);
      this.productCardsViewsAccess = !!token?.role.includes('ProductCardsViewsAccess');
      this.constructorViewsAccess = !!token?.role.includes('CanCreateCustomPages');
      this.analyticsSalesAccess = !!token?.role.includes('ViewAnalyticsSales');
      this.analyticsSearchAccess = !!token?.role.includes('ViewAnalyticsSearch');
      this.analyticsRatingAccess = !!token?.role.includes('ViewAnalyticsRating');
      this.analyticsViewsAccess = this.analyticsSalesAccess || this.analyticsSearchAccess
        || this.analyticsRatingAccess || this.productCardsViewsAccess;
    },
  },
});
