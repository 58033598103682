import { Commit, Module } from 'vuex';
import RootState from '@/store/models';
import { AddMessage, Message, MessengerState } from '@/store/messenger/models';

const ADD = 'ADD';
const DEL = 'DEL';
const DROP = 'DROP';
const PENDING = 'PENDING';

const addMessage = (commit: Commit, payload: AddMessage) => {
  const id = new Date().getTime();
  commit(ADD, { ...payload, id });
  setTimeout(() => {
    commit(DEL, id);
  }, 5000);
};

const moduleMessage: Module<MessengerState, RootState> = {
  namespaced: true,
  state: (): MessengerState => ({
    messages: {},
    pending: false,
  }),
  mutations: {
    [ADD]: (state: MessengerState, payload: Message) => {
      state.messages[payload.id] = payload;
    },
    [DEL]: (state: MessengerState, id: string) => {
      delete state.messages[id];
    },
    [DEL]: (state: MessengerState) => {
      state.messages = {};
    },
    [PENDING]: (state: MessengerState, pending: boolean) => {
      state.pending = pending;
    },
  },
  actions: {
    add: ({ commit, state }, payload: Message): void => {
      if (state.pending) return;
      // Logout on 401 and 418.
      // Drop all messages
      // Add "login message"
      // Block messages for 5 sec
      if (payload.status === 401 || payload.status === 418) {
        commit(DROP);
        addMessage(commit, { status: 'info', title: 'Пожалуйста, войдите в систему повторно' });
        commit(PENDING, true);
        setTimeout(() => {
          commit(PENDING, false);
        }, 5000);
        return;
      }
      addMessage(commit, payload);
    },
    del: ({ commit }, id: string) => {
      commit(DEL, id);
    },
    drop({ commit }): void {
      commit(DROP);
    },
  },
  getters: {
    messages: (state: MessengerState): Message[] => Object.values(state.messages),
  },
};

export default moduleMessage;
