import { createApp } from 'vue';
import { setInterceptors } from '@/client';
import IconSvg from '@/components/icon-svg.vue';
import App from './App.vue';
import router from './router';
import store from './store';

setInterceptors(store);

createApp(App)
  .component('IconSvg', IconSvg)
  .use(store)
  .use(router)
  .mount('#app');
