
export default {
  name: 'InputEmail',
  props: {
    label: String,
    placeholder: String,
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
    hint: String,
  },
};
