import UserRoles = Models.UserRoles;

interface AuthTokenJSON {
  Admin?: '1';
  role: UserRoles;
  phone?: string; // user phone
  exp: number; // expire at
  sub?: string; // sessionId
  nbf: number;
  iat: number;
  jti: string; // user id
}

interface AuthToken extends AuthTokenJSON {
  expires: Date;
}

// nodejs polyfill
function polyAtob(a: string): string {
  let b = '';
  if (typeof atob !== 'undefined') b = atob(a);
  // @ts-ignore
  if (typeof Buffer !== 'undefined') b = Buffer.from(a, 'base64').toString('binary');
  return b;
}

const JWT = {
  decode(tokenValue: string): AuthToken | null {
    let tokenData: AuthToken | null = null;
    const token = tokenValue.split('.')[1];
    if (!token) return null;

    const strArr = polyAtob(token.replace(/-/g, '+')
      .replace(/_/g, '/'))
      .split('')
      .map((c: string) => `%${('00'.concat(c.charCodeAt(0).toString(16))).slice(-2)}`);

    let payload = null;
    try {
      payload = decodeURIComponent(strArr.join(''));
    } catch (e) {
      console.error('JWT decode error');
    }
    if (!payload) return null;

    let tokenJson: AuthTokenJSON | null = null;
    try {
      tokenJson = JSON.parse(payload);
    } catch (e) {
      console.error('JWT decode parse error');
    }

    if (tokenJson) {
      let expires: Date | undefined = new Date(tokenJson.exp * 1e3);
      if (Number.isNaN(expires.getTime())) expires = new Date(Date.now() + 3600000); // 1h
      tokenData = {
        ...tokenJson,
        expires,
      };
    }

    return tokenData;
  },
};

export {
  JWT as default,
  JWT,
  AuthToken,
};
