
import IconSvg from '@/components/icon-svg.vue';

export default {
  name: 'LinkButton',
  components: { IconSvg },
  props: {
    href: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: false,
    },
    symbol: {
      type: String,
      required: false,
    },
  },
};
