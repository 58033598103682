
import AuthInfo from '@/components/layout/header/AuthInfo.vue';
import Logo from '@/components/layout/Logo.vue';

export default {
  name: 'Header',
  components: {
    AuthInfo,
    Logo,
  },
};
