import { Module } from 'vuex';
import RootState from '@/store/models';
import {
  SearchQueriesState,
  SearchQueryFilter,
  SearchQueryRequest,
  SearchQueryResponse,
} from '@/store/analytics/search-queries/models';
import { reasonMessage } from '@/utils/reason-message';
import { client } from '@/client';
import { AxiosError, AxiosResponse } from 'axios';

const FILTER = 'FILTER';
const TABLEFILTER = 'TABLEFILTER';
const KEYWORDS = 'KEYWORDS';
const RESPONSE = 'RESPONSE';
const LOADING = 'LOADING';
const RESET = 'RESET';
const DROP = 'DROP';

const moduleSearchQueries: Module<SearchQueriesState, RootState> = {
  namespaced: true,
  state: () => ({
    filter: {
      beginDate: '',
      endDate: '',
      keywords: [],
      page: 0,
      pageSize: 10,
      sortField: undefined,
      sortType: undefined,
    },
    tableFilter: undefined,
    response: undefined,
    loading: false,
  }),
  actions: {
    async load({ commit, dispatch, state }, payload): Promise<void> {
      commit(LOADING, true);
      const filter = payload ? state.tableFilter : state.filter;
      const params = {
        ...filter,
        ...payload,
      };
      return client.put('/SearchStat', params)
        .then(async (response: AxiosResponse<SearchQueryResponse>) => {
          if (!response.data.data.length) {
            await dispatch('message/add', {
              status: 'error',
              text: 'Поиск не дал результатов',
            }, { root: true });
          }
          commit(RESPONSE, response.data);
          if (!payload) commit(TABLEFILTER, state.filter);
        })
        .catch(async (reason: AxiosError) => {
          await dispatch('message/add', reasonMessage(reason), { root: true });
          commit(RESPONSE, undefined);
          commit(TABLEFILTER, undefined);
        })
        .finally(() => {
          commit(LOADING, false);
        });
    },
    removeKeyword({ dispatch, state }, keyword): void {
      const { keywords } = state.filter;
      if (!(keywords && keywords.length)) return;

      const updatedKeywords = (Object.values(keywords) || []).filter((v: string) => v !== keyword);
      dispatch('filter', { keywords: updatedKeywords });
    },
    async filter({ commit, state }, payload: SearchQueryFilter) {
      commit(FILTER, { ...state.filter, ...payload });
    },
    async getReportUrl({ dispatch, state }): Promise<void> {
      return client.put('/SearchStat/Xlsx', state.tableFilter)
        .then((response: AxiosResponse<string>) => {
          window.open(response.data);
        })
        .catch(async (reason: AxiosError) => dispatch('message/add', reasonMessage(reason), { root: true }));
    },
    reset({ commit }) {
      commit(RESET);
    },
    drop({ commit }) {
      commit(DROP);
    },
  },
  mutations: {
    [RESPONSE]: (state: SearchQueriesState, payload: SearchQueryResponse) => {
      state.response = payload;
    },
    [KEYWORDS]: (state: SearchQueriesState, keywords: string[]) => {
      state.filter.keywords = keywords;
    },
    [LOADING]: (state: SearchQueriesState, payload: boolean) => {
      state.loading = payload;
    },
    [FILTER]: (state: SearchQueriesState, payload: SearchQueryRequest) => {
      state.filter = payload;
    },
    [TABLEFILTER]: (state: SearchQueriesState, payload: SearchQueryRequest) => {
      state.tableFilter = payload;
    },
    [RESET]: (state: SearchQueriesState) => {
      state.filter = {
        beginDate: '',
        endDate: '',
        keywords: [],
        page: 0,
        pageSize: 10,
        sortField: undefined,
        sortType: undefined,
      };
    },
    [DROP]: (state: SearchQueriesState) => {
      state.response = undefined;
      state.tableFilter = undefined;
      state.filter = {
        beginDate: '',
        endDate: '',
        keywords: [],
        page: 0,
        pageSize: 10,
        sortField: undefined,
        sortType: undefined,
      };
    },
  },
  getters: {
    response: (state: SearchQueriesState) => state.response,
    loading: (state: SearchQueriesState) => state.loading,
    filter: (state: SearchQueriesState): SearchQueryRequest => state.filter,
  },
};

export default moduleSearchQueries;
