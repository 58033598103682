
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/buttons/Button.vue';

export default defineComponent({
  name: 'RecoverySuccess',
  components: {
    ButtonComponent,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  computed: {
    mailer(): string {
      return this.email.split('@')[1];
    },
  },
});
