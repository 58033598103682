
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/components/messages/Message.vue';
import HTTPMessage from '@/components/messages/HTTPMessage.vue';

export default defineComponent({
  name: 'Messages',
  components: {
    Message,
    HTTPMessage,
  },
  mounted() {
    this.offsetTopMessage();
  },
  computed: mapGetters('message', ['messages']),
  methods: {
    ...mapActions('message', ['del']),
    offsetTopMessage(): string {
      const getHeight = (className: string): number => document
        .getElementsByClassName(className)[0]?.getBoundingClientRect().height || 0;

      const headerHeight: number = getHeight('header');
      const authHeaderHeight: number = getHeight('header-logo');

      const offsetTop = window.pageYOffset
        || window.scrollY
        || (document.documentElement && document.documentElement.scrollTop)
        || (document.body && document.body.scrollTop) || 0;

      const headHeight: number = headerHeight || authHeaderHeight;

      return offsetTop > headHeight
        ? '10px'
        : `${(headHeight - offsetTop) + 10}px`;
    },
  },
});
