// @ts-ignore
// eslint-disable-next-line import/named
import { createApiClient, ARU_BACKEND_URL } from 'create-api';
import { AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import cookies from '@/utils/cookie';
import { Store } from 'vuex';
import RootState from '@/store/models';
import AuthInfo = Models.Auth.AuthInfo;

const AUTH_TOKEN_NAME = 'supplier-token';
const RESET_TOKEN_URL = '/Auth/ResetToken';

const client: AxiosInstance = createApiClient();

function setInterceptors(store: Store<RootState>): void {
  client.interceptors.response.use(async (response: AxiosResponse) => {
    if (response.config.url === RESET_TOKEN_URL) return response;
    const hash: string | undefined = response.headers['x-user-hash'];
    if (!hash) return response;
    const prevHash: string | undefined = cookies.get('x-user-hash');

    if (hash !== prevHash) {
      client.post(RESET_TOKEN_URL)
        .then((authResponse: AxiosResponse<AuthInfo>) => {
          const { token, lifeTime, permissionsHashCode } = authResponse.data;
          cookies.set('x-user-hash', permissionsHashCode);
          setAuthorization(token, lifeTime);
          store.dispatch('user/token', token);
        })
        .catch((reason: AxiosError) => {
          console.log('reset token error', reason.response?.data);
        });
    }

    return response;
  }, async (error: AxiosError) => {
    if (
      error.code === '401'
      || error.response?.status === 401
      || error.code === '418'
      || error.response?.status === 418
    ) {
      await store.dispatch('user/logout');
    }
    return Promise.reject(error);
  });
}

function setAuthorization(token?: string, lifeTime?: number): void {
  if (token) {
    cookies.set(AUTH_TOKEN_NAME, token, { expires: lifeTime });
  } else {
    cookies.del(AUTH_TOKEN_NAME);
  }
  client.defaults.headers.Authorization = token ? `Bearer ${token}` : undefined;
}

export { AxiosResponse, AxiosError } from 'axios';

export {
  client,
  setAuthorization,
  setInterceptors,
  AUTH_TOKEN_NAME,
  ARU_BACKEND_URL,
};
