
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';
import SideNavigation from '@/components/layout/aside/SideNavigation.vue';

export default defineComponent({
  name: 'Aside',
  components: {
    SideNavigation,
    IconSvg,
  },
  created() {
    this.checkDesktop();
    if (typeof (window) !== 'undefined') window.addEventListener('resize', this.checkDesktop);
  },
  watch: {
    $route() {
      if (this.sidebarVisible) {
        setTimeout(() => {
          this.closeSidebar();
        }, 350);
      }
    },
  },
  data() {
    return {
      isDesktop: true,
    };
  },
  methods: {
    checkDesktop(): void {
      this.isDesktop = window.innerWidth > 1024;
    },
    openSidebar(): void {
      this.$store.dispatch('sidebar/open');
    },
    closeSidebar(): void {
      this.$store.dispatch('sidebar/close');
    },
    toggleSidebar(): void {
      this.$store.dispatch('sidebar/toggle');
    },
  },
  computed: {
    sidebarVisible(): boolean | null {
      return this.$store.state.sidebar.visible;
    },
  },
});
