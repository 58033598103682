import { RouteRecordRaw } from 'vue-router';

const productRoutes: Array<RouteRecordRaw> = [
  {
    meta: { title: 'Товары' },
    path: '/products',
    alias: '/products/',
    name: 'Products',
    component: () => import(
      /* webpackChunkName: "products" */
      '@/views/products/Products.vue'
    ),
  },
  {
    path: '/products/:uid',
    alias: '/products/:uid/',
    name: 'Product',
    component: () => import(
      /* webpackChunkName: "products" */
      '@/views/products/product/Product.vue'
    ),
  },
  {
    path: '/products/:uid/changing',
    alias: '/products/:uid/changing/',
    name: 'Changing',
    component: () => import(
      /* webpackChunkName: "changing" */
      '@/views/products/changing/Changing.vue'
    ),
  },
];

export default productRoutes;
