import { Module } from 'vuex';
import RootState from '@/store/models';
import { reasonMessage } from '@/utils/reason-message';
import { UserState } from '@/store/user/models';
import { authorization, cancelGetInfo, getUserInfo } from '@/store/user/api';
import { setAuthorization } from '@/client';
import { AxiosError } from 'axios';
import LkpUserDetails = Models.LkpUser.LkpUserDetails;

const USER = 'USER';
const TOKEN = 'TOKEN';

const moduleUser: Module<UserState, RootState> = {
  namespaced: true,
  state: (): UserState => ({
    info: null,
    token: null,
  }),
  mutations: {
    [USER]: (state: UserState, payload: LkpUserDetails | null) => {
      state.info = payload;
    },
    [TOKEN]: (state: UserState, token?: string) => {
      state.token = token || null;
    },
  },
  actions: {
    login({ dispatch }, { email, password }) {
      return authorization({
        type: 'ByPassword',
        login: email,
        code: password,
      }).then((token: string) => {
        dispatch('token', token);
        dispatch('load');
      });
    },
    logout({ commit }) {
      cancelGetInfo('logout');
      commit(USER, null);
      commit(TOKEN, null);
      setAuthorization(undefined);
    },
    load: async ({ commit, dispatch }) => getUserInfo().then((user: LkpUserDetails) => {
      commit(USER, user);
    }).catch(async (err: AxiosError) => {
      await dispatch('message/add', reasonMessage(err), { root: true });
      commit(USER, null);
      const { status } = err.response || {};
      if (status === 401 || status === 403) await dispatch('logout');
    }),
    token({ commit }, token?: string) {
      commit(TOKEN, token);
    },
  },
};

export default moduleUser;
