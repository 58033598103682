import { defineComponent, PropType } from 'vue';
import Spinner from '@/components/spinner.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import IconSvg from '@/components/icon-svg.vue';
import { Option } from '@/components/inputs/models';
import { client } from '@/client';
import draggable from 'vuedraggable';
import { cloneDeep } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { reasonMessage } from '@/utils/reason-message';
import { mapActions } from 'vuex';
import filterPluralize from '@/utils/filter.pluralize';
import ResponsePage = Models.Common.ResponsePage;
import GoodInfo = Models.Good.GoodInfo;

interface GoodsInfoForSelected extends GoodInfo {
  selected?: boolean;
  multiple?: boolean;
}

interface ComponentData {
  goods: GoodsInfoForSelected[];
  loading: boolean;
  pendingScroll: boolean;
  page: number;
  totalPages: number | undefined;
  selectedGoodsCopy: GoodsInfoForSelected[];
  multipleGoods: GoodsInfoForSelected[];
  dragStartIndicator: boolean;
  selectedCheckboxes: string[];
  allCheckboxes: boolean;
  timeout?: number;
  goodName: string;
}

export default defineComponent({
  name: 'GoodsSelector',

  emits: ['close', 'goToProviderPopup', 'result'],

  components: {
    Spinner,
    IconSvg,
    InputCheckbox,
    draggable,
  },

  props: {
    showGoToProviderPopup: {
      type: Boolean,
      default: () => true,
    },
    showSelectedCounter: {
      type: Boolean,
      default: () => false,
    },
    showProvider: {
      type: Boolean,
      default: () => true,
    },
    provider: {
      type: Object as PropType<Option>,
    },
    providers: {
      type: Object as PropType<Option[]>,
    },
    selectedGoods: {
      type: Array as PropType<{uid: string, name: string}[]>,
      default: () => [],
    },
    maximumForSelected: {
      type: Number,
      default: () => 10,
    },
    singleProduct: {
      type: Boolean,
      default: () => false,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => 'Выбор товаров',
    },
  },

  data(): ComponentData {
    return {
      goods: [],
      loading: false,
      pendingScroll: false,
      page: 0,
      totalPages: undefined,
      selectedGoodsCopy: cloneDeep(this.selectedGoods),
      multipleGoods: [],
      dragStartIndicator: false,
      selectedCheckboxes: [],
      allCheckboxes: false,
      timeout: undefined,
      goodName: '',
    };
  },

  async mounted() {
    await this.getGoods('');
    const el = this.$refs.originalList as HTMLDivElement;
    if (el) el.addEventListener('scroll', this.onScroll);

    if (typeof (window) !== 'undefined') window.addEventListener('keydown', this.closePopupByEsc);
  },

  beforeUnmount() {
    if (typeof (window) !== 'undefined') window.removeEventListener('keydown', this.closePopupByEsc);
  },

  methods: {
    ...mapActions('message', {
      addMessage: 'add',
    }),

    close(): void {
      this.$emit('close');
    },

    closePopupByEsc(e: any): void {
      if (e.keyCode === 27) this.close();
    },

    pluralize(value: number, n1 = '', n2 = '', s5?: string): string {
      return filterPluralize(value, n1, n2, s5);
    },

    onScroll(el: Event) {
      const target = el.target as HTMLDivElement;
      // При изменении масштаба браузера пиксель дробится. Добавляем погрешность
      // А дабы не двоить запросы добавляем пендюшку
      if (target.scrollHeight <= (Math.floor(target.scrollTop + target.clientHeight + 5))
        && this.totalPages !== this.page + 1
        && !this.pendingScroll) {
        this.pendingScroll = true;
        this.page += 1;
        this.getGoods(this.goodName).finally(() => {
          this.pendingScroll = false;
        });
      }
    },

    async getGoods(goodName: string): Promise<void> {
      if (!this.totalPages) this.loading = true;
      let providers: string | string[] = [];
      if (this.provider?.value) {
        providers = this.provider?.value ? [this.provider.value] : [];
      }
      if (this.multiple) {
        providers = this.providers?.reduce((result: string[], current: Option) => [...result, current.value], []) || [];
      }
      return client.get('/Good/List', {
        params: {
          pageSize: 100,
          page: this.page,
          goodName,
          providers: providers || [],
        },
      })
        .then((response: AxiosResponse<ResponsePage<GoodInfo>>) => {
          if (this.selectedGoodsCopy?.length && response.data?.data && response.data.data.length) {
            const set = new Set();
            this.selectedGoodsCopy.forEach((item) => {
              set.add(item.uid);
            });
            response.data.data.forEach((good: GoodsInfoForSelected) => {
              if (good.uid && set.has(good.uid)) {
                good.selected = true;
              }
            });
          }
          this.totalPages = response.data.totalPages;
          if (goodName?.length && response.data?.data && response.data.data.length) {
            const res = response.data.data.map((item: GoodInfo) => {
              const regexp = RegExp(`${goodName}`, 'gi');
              return {
                ...item,
                name: item.name ? item.name.replace(regexp, `<b>${goodName}</b>`) : '',
              };
            });
            this.goods = [...this.goods, ...res as GoodInfo[]] || [];
          } else {
            this.goods = [...this.goods, ...response.data.data as GoodInfo[]] || [];
          }
        })
        .catch(async (reason: AxiosError) => this.addMessage(reasonMessage(reason)))
        .finally(() => {
          this.loading = false;
        });
    },

    addProductForSelected(idx: number): void {
      if (!this.goods?.length) return;
      this.goods[idx] = {
        ...this.goods[idx],
        selected: true,
      };
      this.selectedGoodsCopy.push(this.goods[idx]);
      this.allCheckboxes = false;
    },
    removeProductFromSelected(idx: number, uid: string, rightSide?: boolean): void {
      if (!this.goods?.length) return;
      if (this.selectedCheckboxes.length) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter((u: string) => u !== uid);
      }
      if (rightSide) {
        const foundedGoodIndex = this.goods?.findIndex((good: GoodsInfoForSelected) => good.uid === uid);
        if (foundedGoodIndex !== undefined) {
          this.goods[foundedGoodIndex] = {
            ...this.goods[foundedGoodIndex],
            selected: false,
          };
        }
      } else {
        this.goods[idx] = {
          ...this.goods[idx],
          selected: false,
        };
      }
      this.selectedGoodsCopy = this.selectedGoodsCopy?.filter((good: GoodsInfoForSelected) => good.uid !== uid);
    },

    dragStart(evt: any): void {
      this.dragStartIndicator = true;

      // eslint-disable-next-line
      const targetObject = evt.item['_underlying_vm_'];
      if (!targetObject.multiple) this.deselectMultiple();
    },

    dragEnd(evt: any): void {
      this.dragStartIndicator = false;

      // eslint-disable-next-line
      const targetObject = evt.item['_underlying_vm_'];
      if (!targetObject.multiple && evt.pullMode) {
        this.goods[evt.oldIndex] = {
          ...this.goods[evt.oldIndex],
          selected: true,
        };
        this.allCheckboxes = false;
      } else {
        if (!evt.pullMode) return;
        this.multipleGoods = this.multipleGoods.filter((good: GoodsInfoForSelected) => good.uid !== targetObject.uid);
        this.selectedGoodsCopy = [
          ...this.selectedGoodsCopy,
          ...this.multipleGoods,
        ];
        this.allCheckboxes = false;
        this.goods = this.goods.map((good: GoodsInfoForSelected) => {
          if (good.multiple) {
            return {
              ...good,
              multiple: false,
              selected: true,
            };
          }
          return good;
        });
        this.multipleGoods = [];
      }
    },

    selectMultiple(good: GoodsInfoForSelected, index: number): void {
      if (!this.goods?.length || good.selected) return;
      if (good.multiple) {
        this.multipleGoods = this.multipleGoods.filter((el: GoodsInfoForSelected) => el.uid !== good.uid);
        this.goods[index] = {
          ...good,
          multiple: false,
        };
      } else {
        if ((this.multipleGoods.length + this.selectedGoodsCopy?.length) >= this.maximumForSelected) return;
        this.goods[index] = {
          ...good,
          multiple: true,
        };
        this.multipleGoods.push(good);
      }
    },
    deselectMultiple(): void {
      if (!this.multipleGoods.length) return;
      this.goods = this.goods.map((good: GoodsInfoForSelected) => ({
        ...good,
        multiple: false,
      }));
      this.multipleGoods = [];
    },

    switchCheckbox(c: [] | boolean): void {
      if (typeof c === 'boolean') {
        if (c) {
          this.selectedGoodsCopy.forEach((good: GoodsInfoForSelected) => {
            if (good.uid) this.selectedCheckboxes = [...this.selectedCheckboxes, good.uid];
          });
          this.allCheckboxes = true;
        } else {
          this.selectedCheckboxes = [];
          this.allCheckboxes = false;
        }
      } else {
        this.allCheckboxes = this.selectedGoodsCopy?.length === this.selectedCheckboxes.length;
      }
    },

    deleteSelectedCheckboxes(): void {
      const set = new Set(this.selectedCheckboxes);
      this.selectedGoodsCopy = this.selectedGoodsCopy?.filter(
        (good: GoodsInfoForSelected) => good.uid && !set.has(good.uid),
      );
      this.goods?.forEach((good: GoodsInfoForSelected) => {
        if (good.uid && set.has(good.uid)) {
          good.selected = false;
        }
      });
      this.allCheckboxes = false;
      this.selectedCheckboxes = [];
    },

    async search(goodName: string): Promise<void> {
      this.loading = true;
      this.goodName = goodName;
      this.page = 0;
      this.goods = [];
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.totalPages = undefined;
        this.page = 0;
        this.goods = [];
        this.getGoods(goodName);
      }, 1000);
    },

    emitResult(): void {
      const goods = this.selectedGoodsCopy.map((good) => {
        // strip html from name
        const name = !good.name ? good.name : good.name.replace(/<[^>]*>?/gm, '').toUpperCase();
        return { ...good, name };
      });
      this.$emit('result', goods);
      this.close();
    },

    goToProviderPopup(): void {
      this.$emit('goToProviderPopup', this.selectedGoodsCopy);
    },
  },
});
