
import ButtonComponent from '@/components/buttons/Button.vue';
import LinkButton from '@/components/buttons/LinkButton.vue';

export default {
  name: 'BlockedUser',
  components: {
    LinkButton,
    ButtonComponent,
  },
  props: {
    managerEmail: String,
    managerName: String,
  },
};
